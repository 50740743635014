.catalog-page-wrapper {
  position: relative;
  height: calc(100dvh - 140px);
  overflow-y: auto;

  @media only screen and (min-width: 48em) {
    height: calc(100dvh - 80px);
  }
}

.actions-wrapper {
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  overflow-y: hidden;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 99.9%
  );

  @media only screen and (min-width: 48em) {
    top: 0;
  }
}

.div-navigation-moments {
  z-index: 4;
  top: 0;
  right: 0;
  left: 0;
  display: grid;
  padding-bottom: 8px;
  overflow-y: hidden;
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}

// .action-button-group {
//   &.button-navigation-catalog {
//     position: sticky;
//     z-index: 10;
//     top: 0;

//     @media only screen and (max-width: 768px) {
//       top: 0;
//     }
//   }
// }

.action-button-group {
  &.button-navigation-catalog {
    .action-button {
      min-width: 150px;
    }
  }
}

.profile_dots {
  position: relative;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // top: calc(-20px - 5%);
  //  right: 14px;
  .right_dots {
    display: inline-flex;
    align-items: center;
  }
  .icon-dot {
    z-index: 1;
    border-radius: 24px;
    width: 10%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  .statistics-info {
    display: inline-flex;
    align-items: center;
  }
}

.profile__icon:before {
  color: var(--pink-light);
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  .profile-ia-box {
    border-radius: 4px;
    padding: 4px 8px;
    color: var(--white);
    font-weight: 700;
    font-size: 12px;
    background: var(--charcoal);
  }
}
.catalog-page {
  margin-bottom: 24px;
  display: grid;
  justify-content: center;
  padding: 8px;
  padding-top: 16px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-auto-columns: 280px;

  gap: 8px;

  @media only screen and (min-width: 48em) {
    padding: 16px;
    gap: 16px;
  }

  .profile-card {
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    height: auto;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
    grid-column: span 1;
    img {
      aspect-ratio: 4 / 5;
      /* Ensures the image keeps a 4:5 aspect ratio */
      object-fit: cover;
      /* Ensures the image covers the container */
    }

    &::after {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      content: "";
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
      transition: opacity 0.3s ease;
    }

    &::before {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      pointer-events: none;
      content: "";
      background: linear-gradient(
        to top,
        rgb(0 0 0 / 76%) 0%,
        rgb(0 0 0 / 0%) 100%
      );
      transition: opacity 0.3s ease;
    }

    &:hover::after {
      cursor: pointer;
      opacity: 0;
    }

    &.remove-linear-gradient {
      &::after {
        cursor: pointer;
        opacity: 0;
      }
    }

    .followers-counter {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }

    .vip-tag {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .background {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        border-radius: 8px 0 0 0;
        width: 0;
        height: 0;
        border-top: 60px solid #000000a6;
        border-right: 60px solid transparent;
      }

      p {
        z-index: 1;
        margin: 12px 0 0 7px;
        color: var(--white);
        font-size: 0.75rem;
        letter-spacing: 0.1em;
      }
    }

    .profile-title {
      position: absolute;
      z-index: 3;
      bottom: 16px;
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 24px;
      gap: 32px;
      width: 100%;

      .profile-img {
        border: 4px solid var(--charcoal);
        border-radius: 50%;
        width: 56px;
        height: 56px;
        object-fit: cover;
      }

      .user-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        justify-content: space-between;
        height: 100%;
        margin-top: 36px;

        .profile-names {
          width: 100%;
          margin-bottom: 8px;

          .user-title {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            gap: 4px;

            img {
              width: 16px;
              height: 16px;
            }
          }

          .user-stats {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }

    [alt="catalog gallery"] {
      border-radius: 16px;
      width: 100%;
      //height: 100%;
      object-fit: cover;
    }

    @media only screen and (min-width: 48em) {
      &.grid-2 {
        grid-column: span 2;
        aspect-ratio: inherit;
      }
    }
  }

  .profile-ia-box {
    border-radius: 4px;
    padding: 4px 8px;
    color: var(--white);
    font-weight: 700;
    font-size: 12px;
    background: var(--charcoal);
  }

  .profile__icon {
    font-size: 24px;
  }

  .profile__icon:before {
    color: var(--pink-light);
  }

  &.mobile-width {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  &.mobile-width-plus {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  &.super-mobile-width {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  &.tablet-width {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  &.widescreen-width,
  &.hyper-widescreen-width {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
