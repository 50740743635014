.toggle-wrapper {
  width: 60px;
  height: 30px;
  background-color: var(--charcoal);
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.toggle-button {
  width: 28px;
  height: 28px;
  background: var(--pink-light);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.toggle-button.left {
  transform: translateX(0);
}

.toggle-button.right {
  transform: translateX(30px);
}
