#code-verification {
    width: 100%;
    display: inline-flex;
    gap: 8px;

    input {
        border: none;
        border-radius: 24px;
        width: 100%;
        height: 48px;
        background-color: var(--dawn);
        color: var(--white);
        font-size: 14px;
        text-transform: capitalize;
        text-align: center;

        &::placeholder {
            color: var(--gray);
        }

        &.warning-input {
            border: 1px solid var(--peach);
        }
    }
}
