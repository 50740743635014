#header-default {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  max-width: 390px;

  &.gap-between {
    gap: 24px;
  }

  .icon-container {
    [alt="Roses Logo"] {
      width: 32px;
      height: 32px;
      padding: 3px;
    }

    [alt="left arrow icon"] {
      width: 16px;
      height: 16px;
      cursor: pointer;

      &:hover {
        transition: all 0.3s ease;
      }
    }
  }

  .description-header {
    font-size: 14px;
  }
}
