.profile-slide {
  overflow-y: auto;
  #profile {
    position: relative;
    z-index: 5;
    margin: 16px 16px;
    display: grid;
    // margin-top: 80px;
    //height: calc(120vh - 334px);
    // padding: 0 24px 48px 24px;
    overscroll-behavior: none;
    box-sizing: border-box;
    // width: 100%;
    // height: auto;
    // display: flex;
    // flex-direction: column;
    // gap: 16px;

    .profile_dots {
      position: absolute;
      z-index: 3;

      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 24px;
      top: 0;

      .right_dots {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        .ia-icon {
          color: white;
          border-radius: 4px;
          padding: 4px 8px;
          font-size: 12px;
          background-color: var(--charcoal);
        }
      }
      .left_dots {
        display: inline-flex;
        align-items: center;
      }
    }

    .profile-banner {
      position: relative;
      margin-bottom: 24px;
      height: calc(80dvh - 80px);
      display: flex;
      align-items: flex-end;
      overflow: hidden;
      &.linear-gradient {
        &::before {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          bottom: -1px;
          left: 0;
          // border-radius: 24px;
          content: "";
          background: linear-gradient(
            to top,
            rgb(0 0 0 / 86%) 0px,
            rgb(0 0 0 / 72%) 100px,
            rgba(0, 0, 0, 0) 300px
          );
        }
      }

      .img-change-buttons {
        width: 100%;
        height: 100%;
        display: flex;

        .icon-chevron-right:before,
        .icon-chevron-left:before {
          color: white;
          font-size: 24px;
        }

        .placeholder-photos {
          z-index: 10;
          width: 100%;
          height: 100%;
          background-color: var(--black);
        }
        .photo-video {
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          border-radius: 16px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .img-change-button-left,
        .img-change-button-right {
          display: flex;
          align-items: center;
          position: relative;
          z-index: 1;
          height: 100%;
          flex: 1;
          cursor: pointer;

          .button-icon {
            width: 32px;
            height: 104px;

            background-color: #00000040;
            .icon-img {
              width: 8px;
            }
          }

          &.img-change-button-left {
            background-color: rgba(255, 255, 255, 0);
            justify-content: flex-start;
            .left-br {
              border-radius: 0 8px 8px 0;
            }
          }

          &.img-change-button-right {
            background-color: rgba(255, 255, 255, 0);
            justify-content: flex-end;
            .right-br {
              border-radius: 8px 0 0 8px;
            }
          }
        }
      }

      .vip-tag {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .background {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          border-radius: 20px 0 0 0;
          width: 0;
          height: 0;
          //-webkit-backdrop-filter: blur(4px);
          border-top: 80px solid rgba(255, 255, 255, 0.15);
          border-right: 80px solid transparent;
        }

        p {
          position: relative;
          z-index: 1;
          margin: 16px 0 0 13px;
          color: var(--white);
          letter-spacing: 0.1em;
        }
      }

      [alt="profile"] {
        z-index: 1;
        border-radius: 24px;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }

      .profile-title {
        position: absolute;
        z-index: 3;
        bottom: 40px;
        left: 50%;
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        //padding: 0 16px;
        transform: translateX(-50%);
        gap: 22px;

        .profile_dots {
          position: relative;
          top: -120px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          // top: calc(-20px - 5%);
          //  right: 14px;

          .icon-dot {
            z-index: 1;
            border-radius: 24px;
            width: 10%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }

        .profile__icon:before {
          color: var(--pink-light);
        }

        .user-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;

          .profile-ia-box {
            border-radius: 4px;
            padding: 4px 8px;
            color: var(--white);
            font-weight: 700;
            font-size: 12px;
            background: var(--charcoal);
          }

          .user-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;

            img {
              width: 16px;
              height: 16px;
            }
          }

          .profile-statistics {
            margin-top: 12px;
            border-radius: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #0000008c;
            padding: 0 16px;
            gap: 8px;

            .statistics-info {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 4px;
              gap: 4px;
            }
          }
        }

        .profile-banner-buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }
      }
    }

    .profile-bio {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .social-buttons {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
      }

      .category-wrapper {
        margin-bottom: 80px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }

      .essentials-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;

        .essential-card {
          border-radius: 16px;
          height: auto;
          display: flex;
          flex-direction: row;
          background-color: var(--dawn);
          padding: 8px;
          gap: 8px;

          &:nth-child(odd):last-child {
            grid-column: span 2;
          }

          .essential-info {
            // max-width: calc(100% - 5px);
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
          }
        }
      }
    }

    .photo-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      img {
        border-radius: 8px;
        width: calc(50% - 4px);
        height: auto;
        object-fit: cover;
      }
    }

    .profile-disclaimer {
      margin: 24px 60px 40px 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;

      .icon-test {
        width: 24px;
        height: 24px;
        background-color: var(--gray);
      }

      p {
        text-align: center;
      }
    }

    .media {
      position: relative;
      border-radius: 16px;
      height: 196px;
      display: flex;
      align-items: center;
      background-color: var(--dawn);
      overflow: hidden;
      //  width: 100%;
      max-width: 100%;

      .media_scroll {
        width: 100%;
        display: inline-flex;
        gap: 8px;
        overflow-y: hidden;
        overflow-x: auto;
        overscroll-behavior: none;
        scroll-snap-type: x mandatory;

        .media-photo {
          border-radius: 8px;
          height: 148px;
          width: 120px;
          display: flex;
          flex-shrink: 0;
          // width: auto;
          cursor: pointer;
          gap: 8px;

          img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            //box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.6);
            transition: box-shadow 0.3s ease;
            //border: none;

            &:first-child {
              margin-left: 24px;
            }

            &:last-child {
              margin-right: 24px;
            }
          }

          &::before {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 8px;
            content: "";
            /*    background: linear-gradient(
                        to top,
                        rgba(0, 0, 0, 1) 0px,
                        rgba(0, 0, 0, 0.6) 40px,
                        rgba(0, 0, 0, 0) 100px
                    );
                    */
            pointer-events: none;
          }
        }
      }
    }

    .my-goals {
      border-radius: 16px;
      width: 100%;
      display: grid;
      align-items: center;
      background-color: var(--dawn);
      padding: 24px;
      //height: 114px;
      grid-template-columns: 4fr 1fr;
      gap: 16px;

      .my-goals_meta {
        display: flex;
        align-items: center;
      }

      .my-goals_logo {
        width: 25px;
      }

      .col-2 {
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--pink-light);
          color: var(--white);
          font-weight: 500;
          font-size: 18px;
          cursor: pointer;
          border-top: 1px solid #ffffff80;
        }
      }
      // h3 {
      //     margin: 0;
      //     font-weight: 700;
      //     font-size: 24px;
      // }
      // p {
      //     margin: 0;
      //     font-size: 12px;
      // }

      .line_progress {
        margin-top: 8px;
        border-radius: 8px;
        width: 100%;
        height: 12px;
        background-color: var(--charcoal);

        .line_progress_complete {
          border: 1px solid var(--pink);
          border-radius: 8px;
          width: 50%;
          height: 12px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          background-color: #ee4e866a;
          min-width: 5%;
          transition: width 1s ease-in-out;

          .line_indicator {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #b30060ed;

            .line_indicator_second {
              border-radius: 50%;
              width: 12px;
              height: 12px;
              background-color: var(--pink-light);
            }
          }
        }
      }
    }

    @media only screen and (min-width: 48em) {
      margin-top: 16px;

      .profile-banner {
        height: calc(88vh - 160px);
      }
    }

    @media only screen and (max-width: 780px) {
      margin: 16px;
    }
  }
}
