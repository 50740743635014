#list-item-radio {
  position: relative;
  width: 100%;
  height: 64px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  cursor: pointer;

  &.column-for-image {
    grid-template-columns: 40px 1fr auto;
  }

  &.column-for-image-and-node {
    grid-template-columns: 40px 1fr auto auto;
  }

  &.column-for-node {
    grid-template-columns: 1fr auto 24px;
  }

  .list-item__wrapper {
    padding: 12px 0;
  }

  .item__image-left-container {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.image-circle {
      border: 1px solid var(--charcoal);
    }

    [alt="left icon"] {
      width: 18px;
      height: 18px;
    }
  }

  .item__main-container {
    margin-left: 16px;

    .main-container__highlight-text {
      color: var(--white);
      font-weight: 400;
      font-size: 14px;
    }

    .main-container__simple-text {
      font-weight: 400;
      font-size: 12px;
    }
  }

  .radio-input-container {
    .radio-input-container__label {
      width: 24px;
      display: flex;

      .radio-input-container__input {
        accent-color: var(--pink);
        cursor: pointer;
      }

      .radio-input-container__input:checked + label {
        color: var(--white);
      }

      .radio-input-container__input:after {
        position: relative;
        top: -4px;
        left: -2px;
        border: 1px solid var(--charcoal);
        border-radius: 15px;
        width: 24px;
        height: 24px;
        display: inline-block;
        background-color: var(--dawn);
        content: "";
        visibility: visible;
        box-sizing: border-box;
      }

      .radio-input-container__input:checked:after {
        position: relative;
        top: -4px;
        left: -1px;
        border: 3px solid var(--dawn);
        border-radius: 15px;
        width: 22px;
        height: 22px;
        display: inline-block;
        background-color: var(--pink-light);
        box-shadow: 0 0 0 1px var(--charcoal);
        content: "";
        visibility: visible;
      }
    }
  }
}
