.chat-message {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: var(--default-color-text);

  &--left {
    margin-bottom: 24px;
  }

  .chat-message__wrapper {
    width: 50%;
    max-width: 240px;
    width: 100%;

    &--left {
      border-radius: 0 24px 24px 24px;
      background-color: var(--gray-dark);
    }

    &--right {
      border-radius: 24px 24px 0 24px;
      background-color: var(--midnight);
      align-self: self-end;
    }

    &--system {
      border: 1px solid var(--gray-dark);
      border-radius: 8px;
      background-color: var(--dawn);
      max-width: none;
    }

    &--file {
      width: 100%;
      padding: 4px;
    }
  }

  .chat-message__text {
    padding: 12px 16px;
    font-size: 14px;
    word-break: break-word;

    &--file {
      padding: 8px 8px 8px 8px;
    }

    &--system {
      display: flex;
      align-items: center;
      font-size: 14px;
      gap: 16px;
      word-break: break-word;
    }
  }

  &--left {
    .chat-message__single {
      border-radius: 0 0 20px 20px;
    }
  }

  &--right {
    align-items: flex-end;

    .chat-message__single {
      border-radius: 0 0 0 20px;
    }
  }

  .chat-message__logo {
    width: 100%;
  }

  .chat-message__time {
    display: flex;
    align-items: baseline;
    padding: 4px;
    color: var(--gray);
    font-size: 11px;
    gap: 4px;
  }

  .chat-message__read-status {
    margin-top: 2px;
    border-radius: 100%;
    width: 6px;
    height: 6px;
    display: block;
    margin-top: 4px;

    &.message-read {
      background-color: var(--pink-light);
    }

    &.unread-message {
      background-color: var(--gray-mid);
    }
  }
}
