.private-list {
  width: 100%;
  height: 100%;
  display: grid;
  padding: 24px;
  grid-template-rows: 80px repeat(auto-fill, minmax(auto, 1fr));

  .icon-left-border {
    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .private-list__list {
    margin-bottom: 16px;
    height: calc(100vh - 394px);
    overflow-y: auto;
  }

  .private-list__button-send-container {
    .button-send {
      border: none;
      border-radius: 24px;
      background-color: var(--charcoal);
      padding: 8px 24px;
      color: var(--white);
      font-family: "Montserrat Variable";
      font-weight: 600;
      font-size: 10px;
      outline: none;
      cursor: pointer;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      &.sended-button {
        background-color: transparent;
        color: var(--gray-light);
      }
    }
  }

  .add-media-page__buttons-container {
    align-self: flex-end;
  }

  @media only screen and (min-width: 48em) {
    padding: 0 24px;

    .page-title-container {
      background: transparent;

      .back-button {
        background-color: var(--charcoal);
      }
    }

    .add-media-page__buttons-container {
      margin-bottom: 24px;
    }
  }
}
