.text-action-card {
  border-radius: 1rem;
  background-color: var(--midnight);
  padding: 1rem;
  // margin: 1.5rem 0 1.5rem 0;

  .principal-text {
    color: var(--white);
    font-weight: 600;
  }
}
