.create-email-verify {
  display: grid;
  height: 100%;

  .create-email-verify__main {
    align-self: center;
    .email-text {
      color: var(--white);
      text-wrap: nowrap;
    }
  }
}
