#media-storage-gallery {
  height: 100%;
  width: 100%;
  background-color: var(--dawn);
  position: relative;

  .media-storage-gallery__page-title {
    padding-left: 16px;
  }

  .media-storage-gallery__cards-wrapper {
    display: grid;
    gap: 8px;
    grid-auto-rows: 143px;
    grid-template-columns: repeat(auto-fill, minmax(116px, 1fr));
    width: 100%;
    overflow-y: auto;
    height: calc(100dvh - 200px);

    padding: 24px 24px 110px 24px;
    .cards-wrapper__file-card {
      height: 143px;
      border-radius: 10px;
      transition: all 0.3s ease-in-out;
      position: relative;
      cursor: pointer;

      .card__tag-file-type {
        font-size: 10px;
        font-weight: 700;
        color: var(--white);
        position: absolute;
        bottom: 16px;
        right: 16px;
        background-color: #33333380;
        padding: 4px 8px;
        border-radius: 4px;
      }

      &.file-card__selected {
        outline: 2px solid var(--pink-light);
        transition: all 0.2s ease-in-out;
      }

      .file-card__card {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }

  @media only screen and (min-width: 48em) {
    padding: 24px 0;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100px;
      top: 72px;
      left: 0;
      z-index: 2;
      background: linear-gradient(to bottom, var(--dawn) 30%, transparent 100%);
      pointer-events: none;
    }

    .media-storage-gallery__page-title {
      display: none;
    }
    .media-storage-gallery__cards-wrapper {
      padding: 40px 40px 110px 40px;

      height: calc(100dvh - 200px);
    }
  }
}
