.user-settings-subscription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100dvh - 80px);
  overflow-y: auto;

  .container-buttons {
    position: absolute;
    bottom: 40px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .roses-container {
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    color: rgb(123, 123, 123);
    gap: 16px;
    margin: 16px 24px;
  }
}
