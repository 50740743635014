

.page-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    h3 {
        font-family: "Montserrat Variable";
        font-weight: 700;
    }

    span {
        font-family: "Montserrat Variable";
        font-weight: 400;
        font-size: 1.5rem;
        color: var(--white);
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-inline: 16px;
    }
}