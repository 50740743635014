#slid-up-container {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-100%);
  transition:
    transform 0.2s ease-out,
    opacity 0.1s ease-out;
  opacity: 0;

  &.open {
    top: 0;
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: end;
    background-color: #0606064d;
    transform: translateY(0);
    opacity: 1;
    overflow: hidden;
  }

  &.close {
    transform: translateY(100%);
    opacity: 0;
  }

  .slid-up-content {
    position: relative;
    border-radius: 33px 33px 0 0;
    width: 100%;
    background-color: var(--dawn);
    padding: 8px 20px 20px 20px;
    touch-action: pan-y;
  }

  .handler-bar {
    margin: 0 auto 24px auto;
    border-radius: 3px;
    width: 33%;
    height: 6px;
    border-top: 6px solid var(--gray-dark);
  }

  .slid-up-title {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
  }
}

.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
}
