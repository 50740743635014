.chat-message-video {
  position: relative;
  height: 100%;
  display: flex;
  max-height: 200px;
  height: 200px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  [alt="play icon"] {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    transform: translate(-50%, -50%);
    opacity: 0.6;
  }

  .chat-message-video__file {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--left {
      border-radius: 0 22px 22px 22px;
    }

    &--right {
      border-radius: 22px 22px 0 22px;
    }
  }

  .chat-message-video__file-text {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .chat-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
}
