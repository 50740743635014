.user-settings-edit-profile {
  .user-settings-edit-profile__input-container {
    display: grid;
    gap: 16px;
  }

  .user-settings-edit-profile__main {
    .profile-link-container {
      display: flex;
      flex-direction: column;

      .profile-link-container__p {
        display: flex;
        gap: 4px;
        font-size: 12px;
      }
    }
    span {
      color: var(--white);
    }
  }
  .character-counter {
    font-size: 14px;
    color: var(--white);
    display: flex;
    justify-content: flex-end;
  }
}
