.ranger__value {
  color: var(--white);
  font-size: 1rem;
}

.range-slider {
  position: relative;
  margin: 3.125rem 0;
  width: 100%;
  height: 0.75rem;
  user-select: none;

  .track {
    position: absolute;
    border-radius: 0.5rem;
    width: 100%;
    height: 0.75rem;
    background-color: var(--charcoal);
  }

  .range {
    position: absolute;
    z-index: 1;
    border: 0.0625rem solid var(--pink-light);
    border-radius: 0.5rem;
    height: 0.75rem;
    background-color: var(--pink-light-35);
    max-width: 100%;
  }

  .thumb {
    position: absolute;
    z-index: 2;
    top: 50%;
    border: 0.25rem solid var(--pink-dark);
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    background: var(--pink-light);
    cursor: grab;
    transform: translate(-50%, -50%);
    transition:
      transform 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
    box-shadow: 0 2px 4px 0 #00000059;

    &:hover {
      box-shadow:
        0 2px 4px 0 #00000059,
        0 0 0 7.5px #ee4e8778;
    }
  }
}
