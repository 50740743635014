.chat-message-photo {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 240px;
  max-height: 300px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  .chat-message-photo__file {
    width: 100%;
    height: 300px;
    object-fit: cover;

    &--left {
      border-radius: 0 22px 22px 22px;
    }

    &--right {
      border-radius: 22px 22px 0px 22px;
    }
  }

  .chat-message-photo__file-text {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .chat-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
}
