.user-settings-privacy {
  overflow-y: auto;
  height: calc(100dvh - 100px);
  .list-container {
    margin: 8px 24px;
  }

  .is-profile-hidden {
    position: relative;
    margin-left: 56px;
    display: flex;
    flex-direction: column;
  }

  .location-list-item {
    .location-list-item-location {
    }
  }
}
