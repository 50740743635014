.modal-preview-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;

  .modal-preview-file__textarea {
    position: static;
    margin: 0;
    padding: 0;
  }
}