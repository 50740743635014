.drop-down__container-button {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: var(--midnight);
  border-radius: 16px;
  padding: 16px;
}

#drop-down-component {
  // position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &[data-active="true"] {
    .input-text-default {
      // position: relative;
      z-index: 3;
    }
  }

  .drop-down-component__input-container {
    position: relative;

    z-index: 2;
    // top: 24px;
    height: 100%;
    width: 100%;
    // display: inline-block;
    max-height: 0;
    // padding: 0;
    // max-height: 0;
    // opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;

    &.drop-down-component__input-container-open {
      max-height: 300px;
      opacity: 1;
      width: 100%;
    }

    &.drop-down-component__input-container-close {
      max-height: 0;
      opacity: 0;
      pointer-events: none;
      width: 100%;
    }

    .input-container__radio-wrapper {
      background-color: var(--midnight);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 16px;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.35);
      .radio-wrapper__content {
        max-height: 300px;
        overflow-y: auto;
      }
    }

    .input-container__checkbox-wrapper {
      background-color: var(--midnight);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 16px;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.35);
      .checkbox-wrapper__content {
        max-height: 300px;
        overflow-y: auto;
      }
    }

    .input-container__list-select-wrapper {
      background-color: var(--midnight);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 16px;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.35);
      .list-select-wrapper__content {
        max-height: 300px;
        overflow-y: auto;
      }
    }

    // .input-container__radio--reverse {
    //   padding-bottom: 24px;
    // }

    // .drop-down-component__li {
    //   &:last-child {
    //     border: none;
    //   }

    //   &:hover {
    //     background-color: (var(--charcoal));
    //   }
    // }
  }

  // .drop-down-component__input-container--reverse {
  //   top: initial;
  //   bottom: calc(100% - 24px);
  //   margin-top: 0;
  //   margin-bottom: 24px;
  //   border-radius: 16px 16px 0 0;
  // }

  // .drop-down__item {
  //   label {
  //     padding: 14.21px 0;
  //   }
  // }
}
