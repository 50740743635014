.user-settings-blocked-location {
  display: flex;
  flex-direction: column;

  .container-button {
    position: relative;
    bottom: 0;
    margin: 1.5rem 1.5rem;
  }

  .img_mock {
    position: sticky;
    z-index: 100;
    top: 0;
    width: 100%;
    height: auto;
  }

  .div__content_scroll {
    height: calc(100dvh - 35rem);
    overflow-y: auto;
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.class-search input {
  border: 1px solid var(--gray-mid);
  border-radius: 1.875rem;
  height: 3rem;
  padding: 1rem 3rem;
  padding-left: 3rem;
  color: var(--white);
}

.class-search svg {
  width: 1.125rem;
}

.class-search input:focus {
  height: 3rem;
  color: var(--white);
}

.class-search input icon {
  background-image: url("../../assets/icons/loupe-gray.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

[class$="--SearchIcon"] {
  margin-right: 0.5rem !important;
  margin-left: 1rem !important;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("../../assets/icons/location-gray.svg");
  background-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
}
// hidden original icon

[class$="--SearchIcon"] svg {
  display: none;
}
// hidden original X icon

[aria-label="Clear"] {
  display: none !important;
}

[class$="--Results"] {
  top: 48px !important;
  left: 50% !important;
  border-radius: 0 0 1rem 1rem !important;
  width: calc(90% - 2rem) !important;
  transform: translateX(-50%) !important;
  max-height: 300px;
}

[class$="--Suggestion"] {
  position: relative;
  padding: 1rem !important;

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(100% - 2rem);
    height: 0.0625rem;
    background-color: var(--charcoal);
    content: "";
    transform: translateX(-50%);
  }
}

[class$="--Suggestion"]:last-of-type {
  position: relative;
  padding: 1rem !important;

  &::after {
    display: none;
  }
}

[class$="ResultsAttribution"] {
  display: none;
}

.modal_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  text-align: center;
  gap: 0.5rem;

  .text {
    color: var(--gray-light);
    max-width: 22.5rem;
  }

  .title {
    color: var(--white);
    font-family: "Montserrat Variable";
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    word-wrap: break-word;
    max-width: 9.125rem;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 780px) {
  .user-settings-blocked-location {
    .div__content_scroll {
      height: calc(100dvh - 18.75rem);
      overflow-y: auto;
    }
  }
}
