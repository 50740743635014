.account-login-page {
  max-width: 390px;
  #terms,
  #privacy {
    margin-top: 0;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .oauth-buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;
      cursor: pointer;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .link-privacy-policy {
    color: var(--pink);
    cursor: pointer;
  }
}
