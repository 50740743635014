@import "../../../scss/functions.scss";
$rem-64: to-rem(64);

.feed-cards {
    position: relative;
    border-radius: to-rem(24);
    width: 100%;
    max-width: to-rem(840);
    // background: var(--black);

    .feed-cards-arrows {
        opacity: 0;
    }

    .feed-cards__progressbar-wrapper {
        position: absolute;
        z-index: 8;
        top: to-rem(16);
        right: to-rem(32);
        left: to-rem(32);

        width: calc(100% - #{$rem-64});
        display: flex;
        gap: to-rem(8);
    }

    .feed-creator-image-post {
        position: relative;

        [alt="creator feed post"] {
            border-radius: to-rem(24);
            width: 100%;
            height: 100%;
            // max-width: to-rem(840);
            // max-height: to-rem(547);
            object-fit: cover;
        }
        // &::after {
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   border-radius: to-rem(24);
        //   width: 100%;
        //   height: 100%;
        //   max-height: to-rem(547);
        //   content: "";
        //   background: linear-gradient(to top, rgb(0 0 0) 0%, rgb(255 255 255 / 0%) 100%);
        //   pointer-events: none;
        // }
    }

    .feed-card-options {
        position: absolute;
        top: to-rem(32);
        right: to-rem(32);

        .feed-card-top-btn {
            position: relative;
            z-index: 2;
            border: 0;
            aspect-ratio: 1/1;
            background: transparent;

            &:hover {
                cursor: pointer;
            }
        }

        .feed-card-options-icon {
            font-size: to-rem(24);

            &::before {
                color: var(--white);
            }
        }

        .feed-card-box {
            position: absolute;
            right: 0;
            margin-top: to-rem(16);
            display: flex;
            flex-direction: column;
            display: flex;
            opacity: 0;
            transform: translateY(-30%);
            transition: all 0.5s;
        }

        .feed-card-box--active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .feed-card-btn {
        position: absolute;
        z-index: 1;
        bottom: to-rem(24);
        left: to-rem(24);
        border-radius: to-rem(24);
        padding: 0 to-rem(16) 0 to-rem(12);
        color: var(--white);
        font-family: "Montserrat Variable";
        font-weight: 600;
        gap: to-rem(8);

        [alt="verify icon"] {
            width: to-rem(12);
            background-color: transparent;
        }
    }

    .feed-card-btn--chat {
        right: to-rem(24);
        left: auto;
        padding: 0 to-rem(1);
    }

    .creator-pack-media-info {
        display: flex;
        gap: to-rem(26);

        .creator-pack {
            height: to-rem(24);
            display: flex;
            gap: to-rem(4);

            .qnt-media-text {
                color: var(--white);
                font-weight: 400;
                font-size: to-rem(16);
                text-align: left;
                opacity: 0.7;
            }

            &.video {
            }

            &.photo {
            }

            &.price {
            }

            &.views {
            }
        }
    }

    .feed-content {
        font-size: to-rem(14);
    }

    .feed-content-title {
        color: var(--white);
        font-weight: 700;
        font-size: to-rem(14);
    }

    .feed-content-text {
        font-size: to-rem(14);
    }

    .feed-content-tag-list {
        display: flex;
        flex-wrap: wrap;
        font-size: to-rem(14);
        gap: to-rem(7);
    }

    .feed-content-tag {
        color: var(--pink-light);
        cursor: pointer;
    }

    .feed-cards-locked {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translate(-50%, -50%);

        .feed-cards-locked-icon {
            width: to-rem(24);
        }

        .feed-cards-locked-price {
            color: var(--white);
            font-weight: 500;
            font-size: to-rem(24);
        }

        .feed-cards-locked-text {
            color: var(--white);
            font-size: to-rem(14);
            opacity: 0.75;
        }
    }
}
