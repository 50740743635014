.chat-textarea {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    .textarea {
        border: none;
        border-radius: 24px;
        width: 100%;
        background-color: var(--dawn);
        padding: 16px 50px 16px 16px;
        color: var(--white);
        font-size: 14px;
        max-height: 100px;
        box-sizing: border-box;
        resize: none;
        overflow-y: auto;
        word-break: break-word;
        &.textarea-active,
        &:focus {
            padding: 16px 56px 16px 16px;
        }

        &::placeholder {
            font-size: 14px;
        }
    }
}

.chat-textarea-ipts {
    width: 100%;
    display: flex;
    align-items: inherit;
    gap: 8px;
    pointer-events: auto;
}

.paper-airplane-button {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    aspect-ratio: 1 / 1;

    .icon:before {
        color: var(--white);
    }
}
