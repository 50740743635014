@import "../../scss/functions.scss";
$rem-80: to-rem(80);

.feed-global {
  height: calc(100dvh - #{$rem-80});
  overflow: auto;

  .feed-global-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feed-global-loading {
    position: relative;
    z-index: 2;
  }

  .feed-global-fallback {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feed-global-logo {
    width: to-rem(24);
  }

  .feed-global-no-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: to-rem(14);
    gap: to-rem(8);
  }

  .feed-global-no-post-support-text {
    color: var(--white);
    font-size: to-rem(18);
  }
}
