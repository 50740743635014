.chat-button-component {
    position: absolute;
    right: 4px;
    bottom: 4px;
    // transform: translateY(-50%);

    .icon {
        &::before {
            color: var(--gray);
        }
    }

    .chat-button {
        border: 1px solid var(--gray-dark);
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        color: var(--gray);
        font-size: 14px;
        outline: none;
        cursor: pointer;
        background: var(--charcoal);
        gap: 8px;

        [data-icon] {
            transform: rotate(0deg);

            &.rotate-image {
                transform: rotate(180deg);
            }
        }
    }

    .chat-button-active {
        width: 40px;
        // transition: all 1s;
        .chat-button-text,
        .icon-photo {
            display: none;
        }
    }

    .chat-button-close {
        border-color: var(--charcoal);
    }

    .show-buttons {
        visibility: visible;
        transform: translateY(0);
        transition: visibility 0s;
        // transform 0.3s ease-in-out,
        // animation: elastic-bounce-up 0.5s ease-in-out;
    }

    .hide-buttons {
        visibility: hidden;
        transform: translateY(100vh);
        transition: visibility 0.1s;
        // transform 0.1s ease-in-out,
    }

    .filter-container {
        position: absolute;
        z-index: 5;
        right: 0px;
        bottom: 0px;
        border-radius: 16px 16px 24px 24px;
        background: var(--charcoal);
        .filter-list,
        .filter-item {
            display: flex;
            flex-direction: column;
        }

        .filter-item {
            border: 0;
            height: 48px;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            background: transparent;
            outline: 0;
            border-bottom: 1px solid var(--gray-dark);

            &:hover {
                cursor: pointer;
            }

            &:last-of-type {
                border: none;
            }
        }

        .filter-image {
            width: 25px;
            height: auto;
        }

        .filter-txt {
            color: var(--white);
            font-size: 14px;
        }
    }

    // @keyframes elastic-bounce-up {
    //   0% {
    //     transform: translateY(100%);
    //   }

    //   40% {
    //     transform: translateY(-50%);
    //   }

    //   70% {
    //     transform: translateY(20%);
    //   }

    //   100% {
    //     transform: translateY(0);
    //   }
    // }
}
