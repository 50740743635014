.fade-carousel-images {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .fade-carousel-slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 2s ease-in-out;

    &.active {
      z-index: 1;
      opacity: 1;
    }

    .slide-background {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .text-container {
        position: absolute;
        z-index: 3;
        bottom: 161px;
        left: 42px;
        max-width: 300px;
        text-align: left;
        z-index: 3;

        img {
          width: 40px;
          height: 40px;
        }

        h2 {
          color: var(--white);
          font-weight: 400;
          font-size: 32px;
        }

        p {
          color: var(--ash);
          font-weight: 400;
          font-size: 18px;
        }
      }
    }

    &.show-gradient-background {
      &::before {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: linear-gradient(
          to top,
          rgb(0 0 0) 0%,
          rgb(0 0 0) 0%,
          rgb(0 0 0 / 0%) 60%
        );
        pointer-events: none;
      }

      &::after {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: radial-gradient(
          circle at 100% 0,
          rgba(231, 0, 124, 0.5) 0%,
          rgba(231, 0, 124, 0) 50%
        );
        pointer-events: none;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  @media only screen and (width >= 30em) {
    .fade-carousel-slide {
      .slide-background {
        .text-container {
          left: 60px;
        }
      }
    }
  }

  @media only screen and (width >= 80em) {
    .fade-carousel-slide {
      .slide-background {
        .text-container {
          left: 140px;
          max-width: 400px;
        }
      }
    }
  }
}
