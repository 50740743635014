.create-number-verify {
    display: grid;

    .phone-text {
        color: var(--white);
        text-wrap: nowrap;
    }
}

.resend-code {
    background-color: transparent;
    color: var(--pink-hot);
    font-size: 12px;
    cursor: pointer;
}
