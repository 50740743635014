.user-settings-change-password {
  display: flex;
  flex-direction: column;
  //gap: 8px;

  .change-password-container {
    display: flex;
    flex-direction: column;
  }

  .buttons-container {
    position: absolute;
    bottom: 0;
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .password-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .separator {
    position: relative !important;
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: var(--charcoal);
  }
}
