.toggle-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-switch {
  width: 50px;
  padding: 12px 0;
  border-radius: 15px;
  border: 1px solid var(--gray-dark);
  background-color: var(--charcoal);
  position: relative;
  transition: background-color 0.3s ease;
}

.toggle-switch.on {
  background-color: #3f0628;
}

.toggle-switch.off {
  background-color: var(--dawn);
}

.toggle-handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--gray-dark);
  position: absolute;
  top: 50%;
  left: 4px;
  transition: left 0.3s ease, background-color 0.3s ease;
  transform: translateY(-50%);
}

.toggle-switch.on .toggle-handle {
  left: 24px;
}

.handle-on {
  background-color: var(--pink-light);
}

.handle-off {
  background-color: var(--gray-dark);
}
