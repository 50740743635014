@import "../../scss/functions.scss";

.user-settings-extract-details {
  &__item {
    border-radius: to-rem(8);
    height: auto;
    background: var(--midnight);
    grid-column: -1;
    border-bottom: to-rem(1) solid var(--charcoal);
  }

  &__value {
    color: var(--gray-light);
    font-size: to-rem(14);
  }

  &__table {
    width: 100%;
    color: var(--white);
    font-size: to-rem(14);

    tr {
      border-bottom: to-rem(1) solid var(--charcoal);
    }

    tr:last-of-type {
      border: none;
    }

    th {
      text-align: left;
    }

    td {
      color: var(--gray-light);
      text-align: end;
    }
    th,
    td {
      padding: to-rem(14, 0);
    }
  }
}
