#input-radio-default {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--gray);
  text-transform: capitalize;
  overflow-y: auto;
  max-height: 160px;
  //height: calc(100dvh - 240px);

  .label-input-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rightIcon {
      cursor: pointer;
    }

    .container-checkbox {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 0.875rem;
      cursor: pointer;

      .checkmark {
        position: absolute;
        top: 50%;
        right: 0;
        border: 1px solid var(--gray-dark);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background-color: var(--charcoal);
        transform: translateY(-50%);

        &::after {
          margin: 3px auto;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          display: none;
          background-color: var(--pink-light);
          content: "";
        }
      }

      input[type="radio"] {
        height: 48px;
        display: none;
        cursor: pointer;
      }

      input:checked ~ .checkmark::after {
        display: block;
      }
    }

    .separator {
      position: absolute;
      bottom: 0;
      left: 16px;
      width: calc(100% - 32px);
      height: 1px;
      background-color: var(--charcoal);
    }
  }
}

@media only screen and (max-width: 780px) {
  #input-radio-default {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--gray);
    text-transform: capitalize;
    overflow-y: auto;
    max-height: 160px;
  }
}
