.user-settings {
  height: calc(100dvh - 78px);
  overflow-y: auto;

  .user-settings__top {
    margin: 16px 24px 0 24px;
  }

  .user-settings-list-item {
    border-radius: 16px;
    height: auto;
    background: var(--midnight);
  }

  .list-container-settings {
    // height: calc(100% - 164px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 24px 24px 24px;
  }
}
