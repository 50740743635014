.create-account-page {
  width: 100%;
  max-width: 390px;

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .texto {
      margin-top: 8px;
      color: var(--gray);
      font-size: 12px !important;
    }

    .teste {
      margin: auto 16px;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
