.user-settings {
  .list-container {
    gap: 0;
  }

  .container-safe-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
  }
}
