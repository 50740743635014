.send-photo-album {
  display: grid;
  grid-template-rows: 80px auto 1fr;

  .send-photo-album__main {
    overflow-y: auto;
    height: calc(100dvh - 315px);

    &.heigh-changed {
      height: calc(100dvh - 264px);
    }
  }
}
