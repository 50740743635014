#content-why-us-wrapper {
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  max-width: 1224px;

  .yellow-div {
    position: relative;
    width: auto;
    flex: 1;

    .text-container {
      position: relative;
      z-index: 3;
      height: auto;

      .title-icon {
        img {
          width: 40px;
          height: 40px;
        }
      }

      .feature-secondary-description {
        & p {
          font-size: 16px;
        }
      }
    }

    * {
      overflow: auto;
    }
  }

  .purple-div {
    width: auto;
    flex: 1;

    img {
      border-radius: 16px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media only screen and (min-width: 48em) {
    flex-direction: row;

    .yellow-div {
      padding: 0 40px;

      #button {
        max-width: 392px;
      }
    }

    .purple-div {
      min-height: 540px;
    }
  }

  .feature-secondary-description {
    & p {
      font-size: 16px;
    }
  }

  .purple-div {
    width: auto;
    flex: 1;

    img {
      border-radius: 16px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media only screen and (min-width: 48em) {
    flex-direction: row;
    margin-bottom: 0;

    .yellow-div {
      padding: 0 40px;

      #button {
        max-width: 392px;
      }
    }

    .purple-div {
      min-height: 540px;
    }
  }

  @media only screen and (min-width: 80em) {
    padding: 24px 0 0 0;
  }
}
