@import "../../scss/functions.scss";
$rem-80: to-rem(80);
$rem-450: to-rem(450);

.feed-page {
    position: relative;
    width: 100%;

    height: calc(100dvh - #{$rem-80});
    display: flex;
    flex-direction: column;
    // grid-template-rows: to-rem(80) 0 auto;
    justify-items: center;
    overflow: auto;

    &.vertical-separator-left {
        border-collapse: collapse;
    }

    .feed-page__page-title {
        position: absolute;
        top: 0;
        width: 100vw;
    }

    .feed-page-main {
        z-index: 2;
        margin: 0 auto;
        width: 100%;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        max-width: to-rem(840);
        // position: absolute;
        overflow-y: auto;

        .feed-page__interaction-nav {
            margin: 0 to-rem(24);

            height: calc(100dvh - #{$rem-450});
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: to-rem(16);

            &.mobile-size {
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
            }

            .interaction-nav__profile-info {
                display: flex;
                align-items: center;
                gap: to-rem(16);

                &.info-column {
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                }

                .profile-info__name {
                    .profile-info__text {
                        margin: to-rem(4) 0;
                        color: var(--white);
                        font-family: "Montserrat Variable";
                        font-weight: 600;
                        font-size: to-rem(16);
                        text-wrap: nowrap;

                        &:last-child {
                            color: var(--ash);
                            font-family: "Roboto Regular";
                            font-weight: 400;
                            font-size: to-rem(14);
                        }
                    }
                }
            }

            .interaction-nav__interaction {
                display: flex;
                gap: to-rem(16);
            }
        }

        .buttons-navigation-feed-container {
            width: 100%;
        }
    }

    .feed-global-loading {
        position: relative;
        z-index: 2;
        text-align: center;
    }

    .feed-global-fallback {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .feed-global-logo {
        width: to-rem(24);
    }

    .feed-global-no-post {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: to-rem(14);
        gap: to-rem(8);
    }

    .feed-global-no-post-support-text {
        color: var(--white);
        font-size: to-rem(18);
    }

    @media only screen and (min-width: 48em) {
        height: 100dvh;

        .feed-page-main {
            height: calc(100dvh - #{$rem-80});

            .feed-page__interaction-nav {
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
            }
        }
    }
}
