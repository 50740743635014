#explicit-content_modal {
  position: relative;
  background-color: var(--midnight);
  border-radius: 24px;
  margin: 24px;

  .explicit-content_modal__content {
    display: grid;

    .content__carousel-wrapper {
      display: none;
      max-width: 390px;
    }
    .content__form-wrapper {
      display: grid;
      gap: 16px;
      justify-self: center;
      align-self: center;
      max-height: calc(100dvh - 200px);
      overflow-y: auto;
      max-width: 342px;
      margin: 24px;

      .form-wrapper__texts {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .content__buttons-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      #button.primary {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    .explicit-content_modal__content {
      grid-template-columns: minmax(200px, 390px) minmax(300px, 600px);
      .content__carousel-wrapper {
        display: block;
      }

      .content__form-wrapper {
        height: calc(100dvh - 100px);
      }
    }
  }
}
