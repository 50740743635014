.user-settings-goals {
  .goals {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 16px 24px;
  }

  .goals__history {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid var(--charcoal);

    &:hover {
      cursor: pointer;
    }

    &:last-of-type {
      border: 0;
    }
  }

  .goals__top {
    display: flex;
    justify-content: space-between;
  }

  .goals__history-title {
    font-size: 14px;

    &--active {
      color: var(--green);
    }

    &--inactive {
      color: var(--gray);
    }
  }

  .goals__bottom {
    display: flex;
    justify-content: space-between;
  }

  .goals__objective {
    font-size: 14px;
  }

  .goals__amount {
    font-size: 14px;
  }
}

.goals__modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
