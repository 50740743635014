#progressbar-component {
  width: 100%;
  height: 100%;

  display: flex;

  .progressbar-component__bar {
    border-radius: 2px;
    height: 2px;
    background-color: var(--ash);
  }

  .progressbar-component__bar-negative {
    border-radius: 2px;
    height: 2px;
    background-color: #dddddd2d;
    // margin: 16px 0;
  }
}
