#verify_email_modal {
  position: relative;
  background-color: var(--midnight);
  border-radius: 24px;
  margin: 24px;
  height: calc(100dvh - 48px);
  max-width: 990px;

  .verify_email_modal__content {
    height: 100%;
    display: grid;

    .content__carousel-wrapper {
      display: none;
      max-width: 390px;
    }

    .content__form-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 342px;
      // width: 100%;
      margin: 24px;

      .form-wrapper__header {
        display: grid;
        gap: 16px;
      }
    }

    .content__buttons-container {
      display: grid;
      gap: 16px;

      #button.primary {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    .verify_email_modal__content {
      grid-template-columns: minmax(200px, 390px) minmax(300px, 600px);
      .content__carousel-wrapper {
        display: block;
      }

      .content__form-wrapper {
        max-width: 342px;
        width: 100%;

        margin: 0 auto;
      }
    }
  }
}
