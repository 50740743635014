.image-container__media-localization-container {
  position: absolute;
  left: 50%;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  .upload-photo-device {
    display: none;
  }

  &.show {
    transform: translate(-50%, 0);
    opacity: 1;
  }

  &.hide {
    transform: translate(-50%, 10%);
    opacity: 0;
    animation: vanish 0.2s ease-in-out;
    pointer-events: none;
  }

  .button-pill-animation {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }

  @keyframes vanish {
    from {
      opacity: 1;
      transform: translate(-50%, 0%);
    }
    to {
      opacity: 0;
      transform: translate(-50%, 50%);
    }
  }

  @media only screen and (min-width: 48em) {
  }
}
