#list-item-toggle {
  position: relative;
  width: 100%;
  padding: 16px 0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  cursor: pointer;

  &.column-for-image {
    grid-template-columns: 56px 1fr auto;
  }

  .item__image-left-container {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.image-circle {
      border: 1px solid var(--charcoal);
    }

    .item__left-icon {
      width: 18px;
      height: 18px;

      &.icon-fill {
        border-radius: 100%;
        width: 100%;
        padding: 2px;
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .item__main-container {
    .main-container__highlight-text {
      color: var(--white);
      font-weight: 400;
      font-size: 14px;
    }

    .main-container__simple-text {
      font-weight: 400;
      font-size: 12px;
    }
  }

  [alt="arrow-right"] {
    width: 7px;
    height: 10px;
  }
}
