.welcome-user {
    height: 100%;

    .welcome-roses {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: 45% 57%;

        &::before {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: linear-gradient(
            to top,
            rgb(0 0 0) 0%,
            rgb(0 0 0) 0%,
            rgb(0 0 0 / 0%) 60%
            );
            pointer-events: none;
        }

        &::after {
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: radial-gradient(
            circle at 100% 0,
            rgba(231, 0, 124, 0.5) 0%,
            rgba(231, 0, 124, 0) 30%
            );
            pointer-events: none;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
    }

    .bottom-text-button-container {
        position: absolute;
        z-index: 2;
        bottom: 43px;
        color: var(--gray-light);
        font-weight: 400;
        font-size: 14px;
    }

    @media only screen and (min-width: 48em) {
        .welcome-roses {
            right: 0;
            left: inherit;
            width: 50%;
        }
    }
}
