.button-icon {
  border: none;
  border-radius: 50%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 3rem;
  cursor: pointer;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  outline: none;
  aspect-ratio: 1 / 1;

  &--primary {
    background: var(--pink-light);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    // box-shadow: #f95f8280 0 4px 20px, #ffffff73 0 1px 0 inset;

    &:hover {
      background: var(--pink-light-hover);
    }

    &:active {
      background: var(--pink-light-active);
    }

    .icon:before {
      color: var(--charcoal);
    }
  }

  &--secondary {
    border: 1px solid var(--pink-light);
    background: transparent;

    .icon:before {
      color: var(--pink-light);
    }

    &:hover {
      opacity: 0.85;

      &:before {
        background: var(--pink-light-hover);
      }
    }

    &:active {
      opacity: 0.65;

      &:before {
        background: var(--pink-light-active);
      }
    }
  }

  &--tertiary {
    background: var(--charcoal);

    .icon:before {
      color: var(--gray-light);
    }

    &:hover {
      color: var(--white);
      opacity: 0.85;
    }

    &:active {
      opacity: 0.65;
    }
  }

  &--inverse {
    background: var(--white);

    .icon:before {
      color: rgba(85, 85, 85, 0.25);
      //REVIEW
    }
  }

  &--transparent {
    border: 1px solid var(--gray-dark);
    background: #00000025;
    backdrop-filter: blur(8px);

    .icon:before {
      color: var(--gray-mid);
    }

    &:active {
      border: 1px solid #ee4e8759;
      background: #ee4e8726;

      .icon:before {
        color: var(--pink-light);
      }
    }
  }
  &--transparent-pink {
    border: 1px solid var(--gray-dark);
    background: #00000025;
    backdrop-filter: blur(8px);

    .icon:before {
      color: var(--pink-light);
    }

    &:active {
      border: 1px solid #ee4e8759;
      background: #ee4e8726;

      .icon:before {
        color: var(--pink-light);
      }
    }
  }

  &--transparent-green {
    border: 1px solid var(--gray-dark);
    background: #00000025;
    backdrop-filter: blur(8px);

    .icon:before {
      color: var(--green);
    }

    &:active {
      border: 1px solid #ee4e8759;
      background: #ee4e8726;

      .icon:before {
        color: var(--green);
      }
    }
  }

  &--transparent-dawn {
    border: 1px solid #ffffff40;
    background: var(--dawn);
    backdrop-filter: blur(8px);

    .icon:before {
      color: var(--white);
    }

    &:active {
      border: 1px solid #ee4e8759;
      background: #ee4e8726;

      .icon:before {
        color: var(--pink-light);
      }
    }
  }

  &--transparentActive {
    border: 1px solid #ee4e8759;
    background: #ee4e8726;

    .icon:before {
      color: var(--pink-light);
    }
  }
}
