.slider-footer-preview {
    width: 100%;
    height: 100%;
    // position: relative;

    .slider-footer-preview__wrapper {
        position: absolute;
        top: 0;
        border-radius: 16px;
        width: 100%;
        height: calc(100vh - 100px);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        overflow: hidden;

        &.active {
            z-index: 10;
            opacity: 1;
        }

        .image-slide {
            // height: 100%;

            .file-slide-carousel {
                width: 100%;
                height: 100%;
                padding: 16px 0;
                // max-width: 460px;
                min-width: 390px;
            }
        }

        .video-slide {
            .file-slide-carousel {
                border-radius: 16px;
                width: 100%;
                height: 100%;
                padding: 16px 0;
                min-width: 390px;
            }
        }

        .slider-button {
            position: absolute;
            z-index: 2;
            top: 50%;
            border: none;
            color: white;
            font-size: 24px;
            transform: translateY(-50%);
            background: transparent;
            cursor: pointer;

            &.prev {
                left: 24px;

                [alt="arrow left"] {
                    width: 18px;
                }
            }

            &.next {
                right: 24px;

                [alt="arrow right"] {
                    width: 18px;
                }
            }
        }
    }

    .preview-files-ul {
        position: fixed;
        z-index: 10;
        right: 24px;
        bottom: 0;
        left: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        // right: 24px;
        overflow-x: scroll;
        overflow-y: hidden;
        gap: 24px;
        backdrop-filter: blur(10px);
        white-space: nowrap;

        .preview-file-li {
            position: relative;
            border-radius: 8px;
            height: 100px;
            min-width: 100px;
            max-width: 100px;
            cursor: pointer;

            [alt="exclude file icon"] {
                position: absolute;
                z-index: 4;
                top: 8px;
                right: 12px;
                border-radius: 100%;
                width: 24px;
                cursor: pointer;
                backdrop-filter: blur(5px);
                transition: all ease 0.3s;

                &:hover {
                    backdrop-filter: blur(7px);
                }
            }

            &:first-child{
                // margin-left: 24px;
            }

            &:last-child{
                margin-right: 48px;
            }

            [alt="preview file"] {
                border-radius: 8px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .preview-file {
                border-radius: 8px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
