#button-pill {
  cursor: pointer;
  margin-top: 16px;

  &:first-child {
    margin-top: 0px;
  }

  .image-container__pill {
    border-radius: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    background-color: #33333333;
    transition: background-color 0.2s ease-in-out;

    &.background-white {
      background-color: var(--white);
    }

    .button__text {
      font-weight: 400;
      font-size: 14px;
      color: var(--white);

      &.color-charcoal {
        color: var(--charcoal);
      }
    }
  }
}
