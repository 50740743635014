.audio-wave {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  .audio-size-paragraph {
    display: contents;
    color: var(--gray);
    font-size: 12px;
  }

  .play-pause-button {
    border: none;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    background-color: transparent;
    outline: none;

    img {
      width: 18px;
      cursor: pointer;
    }
  }
}
