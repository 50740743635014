.locked-files-chat {
  .locked-files-chat__locked {
    position: relative;
    border-radius: 0 22px 0 0;
    width: 100%;
    height: 292px;
    display: flex;
    background-color: var(--midnight);
    grid-column: 1/3;

    [alt="locked images"] {
      position: absolute;
      right: 0;
      left: 0;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      opacity: 35%;
      object-fit: cover;
    }
  }

  .locked-files-chat-locked--with-caption {
    border-radius: 0 22px 22px 22px;

    [alt="locked images"] {
      border-radius: 0 22px 22px 22px;
    }
  }

  .locked-files-chat__info-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    gap: 16px;
  }

  .locked-files-chat__icon-box {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    [alt="camera icon"],
    [alt="images icon"] {
      margin: 4px;
      width: 12px;
      height: 12px;
    }
  }

  .locked-files-chat__icon-text {
    font-size: 12px;
  }

  .locked-files-chat__main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    //gap: 8px;
    transform: translate(-50%, -50%);
    text-align: center;
    text-wrap: nowrap;

    [alt="lock icon"] {
      width: 17px;
      height: 17px;
    }
  }

  .locked-files-chat__title {
    font-weight: 500;
    font-size: 24px;
  }

  .locked-files-chat__text {
    font-size: 14px;
  }

  #button.locked-files-chat__btn {
    margin: 48px 24px 24px 24px;
    backdrop-filter: blur(8px);
  }

  .locked-files-chat__caption {
    padding: 8px 12px 8px 8px;
    // padding: 16px 16px 8px 8px;
  }

  .locked-files-chat__caption-text {
    font-size: 14px;
  }
}
