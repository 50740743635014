@import "../../../scss/functions.scss";

.cards-arrows {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  .cards-arrow-left,
  .cards-arrow-right {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    cursor: pointer;

    .cards-arrow-button-icon {
      margin: to-rem(16);
      border-radius: to-rem(8);
      width: to-rem(40);
      height: to-rem(40);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #00000040;

      .cards-arrow-icon {
        width: to-rem(22);
        height: to-rem(22);
      }
    }

    &.cards-arrow-left {
      justify-content: flex-start;
      background-color: rgba(255, 255, 255, 0);
    }

    &.cards-arrow-right {
      justify-content: flex-end;
      background-color: rgba(255, 255, 255, 0);
    }
  }
}
