.wait-list-application {
  display: grid;
  grid-template-rows: auto auto 1fr;

  .wait-list-application__main {
    .right-info {
      color: var(--gray-light);
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
  }
}
