.password-sign-in-page {
    margin: 0 auto;
    max-width:460px;

    .reset-password {
        background-color: transparent;
        color: var(--pink-hot);
        font-size: 12px;
        cursor: pointer;
    }
}
