.roses-dashboard-container {
    position: relative;
    border-radius: 0.5rem;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--dawn);

    .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        text-align: center;
    }

    .title-text {
        background-color: transparent;
        color: var(--white);
        font-family: "Roboto Bold";
        font-size: 2rem;
        text-transform: uppercase;
    }

    .subtitle-text {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: var(--gray);
        font-size: 0.875rem;

        .icon {
            margin-left: 0.25rem;
            width: 1rem;
            height: 1rem;
            padding: 0.1rem;
        }
    }

    .gold {
        color: var(--gold-light);
    }

    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            margin-bottom: 0.5rem;
        }

        button:last-child {
            margin-bottom: 0;
        }
    }
}
