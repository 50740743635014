.add-referrals {
  display: grid;
  grid-template-rows: auto 1fr 1fr;
  .code-text {
    color: var(--white);
    text-transform: uppercase;
  }

  .add-referrals__main {
  }

  .add-referrals__buttons-container {
    align-self: flex-end;
    display: grid;
    gap: 16px;
  }
}
