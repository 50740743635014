.chat-message-grid {
  .chat-message-grid__grid {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 4px;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      content: "";
      cursor: pointer;
    }
  }

  &.right-layout {
    border-radius: 16px 0 16px 16px;
  }

  &.left-layout {
    border-radius: 0 16px 16px 16px;
  }

  .chat-message-grid__file {
    width: 114px;
    height: 140px;
  }

  .chat-message-grid__files-remains {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--dawn);

    &.right {
      border-bottom-right-radius: 0px;
    }

    &.left {
      border-bottom-right-radius: 20px;
    }
  }

  .chat-message-grid__files-remains-text {
    z-index: 1;
    color: var(--white);
  }
  .chat-message-photo__file,
  .chat-message-video__file {
    border-radius: 0;
    height: 100%;

    &.file-right-1 {
      border-top-left-radius: 20px;
    }

    &.file-right-2 {
      border-top-right-radius: 20px;
    }

    &.file-right-3 {
      border-bottom-left-radius: 20px;
    }

    &.file-right-4 {
      border-bottom-right-radius: 0px;
    }

    &.file-left-1 {
      border-top-right-radius: 0px;
    }

    &.file-left-2 {
      border-top-right-radius: 20px;
    }

    &.file-left-3 {
      border-bottom-left-radius: 20px;
    }

    &.file-left-4 {
      border-bottom-right-radius: 20px;
    }
  }

  .chat-message-video__file {
    position: relative;
    height: 100%;

    [alt="play icon"] {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      transform: translate(-50%, -50%);
      opacity: 0.6;
    }
  }

  .chat-file {
    border-radius: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
