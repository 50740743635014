.user-settings-profile {
  position: relative;
  height: calc(100dvh - 80px);
  // display: flex;
  flex-direction: column;
  //todo rever height felipe
  //overflow-y: auto;
  overflow: hidden;
  overflow-y: auto;

  .actions-wrapper {
    position: sticky;
    z-index: 10;
    top: 0;
    width: 100%;
    //padding: 16px 0;
    overflow-y: hidden;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 99.9%
    );

    @media only screen and (min-width: 48em) {
      top: 0;
    }
  }
  .profile-picture-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
  }

  .user-settings-photo-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .album-photos-quantity {
      position: relative;
      display: block;
      padding: 0 0 0 32px;
      color: var(--gray);
      font-size: 0.75rem;
      text-align: left;
      align-self: flex-end;
    }
  }

  .list-container-personal-info {
    overscroll-behavior: none;
    margin: 0 24px 24px 24px;

    .text-detail {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      font-weight: 400;
      font-size: 14px;
      text-transform: capitalize;
    }
  }

  .container-button_radioList {
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  .album-photos-quantity-list-container {
    /*   height: calc(100dvh - 234px);
    overflow-y: auto; */
    overscroll-behavior: none;
  }

  .user-settings-preferences {
    height: calc(100dvh - 234px);
    overflow-y: auto;
    overscroll-behavior: none;

    .list-container {
      .list-container-settings {
        .text-detail {
          font-weight: 400;
          font-size: 14px;
          text-transform: capitalize;
        }
      }
    }
  }

  .user-settings-slid-up {
    bottom: 80px !important;

    .list-item-slid-up {
      #button {
        margin-top: 12px;
      }
    }
  }
}

.user-settings-slid-up {
  bottom: 80px !important;

  .list-item-slid-up {
    #button {
      margin-top: 12px;
    }
  }
}

.container-radio_radioList {
  overscroll-behavior: none;
  height: 160px;
  overflow-y: auto;
}

.toogle-height {
  color: white;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 380px) {
  .container-radio_radioList {
    overscroll-behavior: none;
    height: 160px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 30em) {
  .container-radio_radioList {
    overscroll-behavior: none;
    height: calc(100dvh - 860px);
    overflow-y: auto;
  }
}
