.modal-preview-file {
    position: relative;
    margin: 40px;
    border-radius: 16px;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: black;
    // height: 100%;
    justify-items: center;
    grid-template-rows: auto 1fr;

    .modal-preview-file__textarea {
        position: absolute;
        bottom: 0px;
        margin: 0 24px;
        width: 100%;
        padding: 0 16px;
        max-width: 460px;
        // min-width: 390px;
    }

    .play-icon {
        position: absolute;
        z-index: 4;
        top: 50%;
        left: 50%;
        width: 10%;
        display: none;
        max-width: 50px;
        min-width: 18px;
        opacity: 0.3;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .play-icon-preview {
        position: absolute;
        z-index: 4;
        top: 50%;
        left: 50%;
        width: 10%;
        max-width: 50px;
        min-width: 18px;
        opacity: 0.3;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .scroll-files {
        width: auto;
        height: calc(100vh - 48px);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        gap: 16px;

        .preview-file-container {
            position: relative;
            width: auto;
            display: flex;
            justify-content: center;
        }

        .file-modal-content {
            width: auto;
        }

        .chat-page-pack {
            position: sticky;
            bottom: 0;
            max-width: 600px;
        }
    }

    .header-modal-preview {
        justify-self: flex-end;

        [alt="close icon"] {
            margin: 24px 0 24px 24px;
            width: 1rem;
            cursor: pointer;
        }
    }

    &.pack-files-message {
        .header-modal-preview {
            [alt="close icon"] {
                margin: 24px 24px 0 0;
            }
        }

        .preview-file-container {
            .file-modal-content {
                }
            }
        }

        .scroll-files {
            padding-bottom: 48px;
        }
    }

    [alt="close icon slide"] {
        position: absolute;
        z-index: 12;
        top: 24px;
        right: 24px;
        width: 16px;
        cursor: pointer;
    }

    .pack-modal-preview__textarea {
        position: absolute;
        z-index: 10;
        bottom: 126px;
        width: 100%;
        max-width: 460px;
    }

    .preview-file-container {
        position: relative;
        border: 4px solid var(--gray-dark);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 180px);

        img {
            border-radius: 8px;
        }

        &.video-wrapper {
            cursor: pointer;
        }

        .file-modal-content {
            width: 100%;
            height: 100%;
            //border-radius: 8px;
            object-fit: cover;
            // min-width: 390px;
            //max-height: calc(100vh - 200px);
        }
    }
