.user-settings-language {
  .div__content {
    height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .list-container {
    gap: 0;
  }
}
