#media-library-page {
  overflow: auto;
  scrollbar-width: none;

  &.media {
    height: calc(100dvh - 80px);
    padding-bottom: 16px;
  }

  .media-library-photos-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 8px;
  }

  @media only screen and (max-width: 48em) {
    &.media {
      height: calc(100dvh - 68px);
      padding: 0 16px;
      padding-bottom: 16px;
    }
  }

  @media only screen and (min-width: 64em) {
  }
}
