#button {
  position: relative;
  border: none;
  border-radius: 24px;
  width: 100%;
  height: 48px;
  padding: 12px 40px;
  color: var(--white);
  font-family: "Montserrat Variable";
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: 1px;
  text-wrap: nowrap;
  outline: none;
  overflow: hidden;
  transition: all ease 0.3s;
  align-self: flex-end;

  p {
    margin: 0;
  }

  &[status="disabled"] {
    opacity: 0.75;
    cursor: not-allowed;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    display: block;
    content: "";
    background: rgba(255, 0, 122, 0.1);
    opacity: 1;
    transition: all ease 0.3s;
  }

  &:not([status="disabled"]) {
    &:hover {
      cursor: pointer;
    }
  }

  &.primary {
    color: var(--white);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    background: var(--pink-light);
    // box-shadow: 0px 1px 0px 0px #ffffff73 inset;

    &:hover {
      background: var(--pink-light-hover);
    }

    &:active {
      background: var(--pink-light-active);
    }

    p {
      color: var(--white);
    }
  }

  &.secondary {
    position: relative;
    background: var(--pink-light);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:before {
      position: absolute;
      z-index: 1;
      border-radius: 24px;
      padding: 1px;
      content: "";
      inset: 0;
      background: var(--pink-light);
      -webkit-mask: linear-gradient(#000 0 0) content-box,
        linear-gradient(#000 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &:hover {
      opacity: 0.85;

      &:before {
        background: var(--pink-light-hover);
      }
    }

    &:active {
      opacity: 0.65;

      &:before {
        background: var(--pink-light-active);
      }
    }
  }

  &.tertiary {
    background-color: var(--charcoal);
    color: var(--gray-light);

    &:hover {
      color: var(--white);
      opacity: 0.85;
    }

    &:active {
      opacity: 0.65;
    }
  }

  &.iconButton {
    background-color: var(--midnight);
    padding: 12px 30px;

    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }

    &:active {
      opacity: 0.65;
    }

    & img {
      width: 24px;
      height: 24px;
      padding: 2px;
    }
  }

  &.quaternary {
    position: relative;
    color: var(--gray);
    background: var(--charcoal);
    background: none;

    &:hover {
      color: var(--white);
    }

    &:after {
      display: none;
    }
  }

  &.quintenary {
    border: 1px solid hsla(0, 0%, 100%, 0.35);
    font-weight: 600;
    letter-spacing: 5%;
    line-height: 24px;
    cursor: pointer;
  }

  &.sexternary {
    border: none;
    font-weight: 600;
    letter-spacing: 5%;
    cursor: pointer;
    background-color: var(--dawn);
  }

  &.septenary {
    background-color: var(--charcoal);
    color: var(--white);

    &:hover {
      color: var(--white);
      opacity: 0.85;
    }

    &:active {
      opacity: 0.65;
    }
  }
}
