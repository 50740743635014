.tooltip {
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__box {
    position: absolute;
    border-radius: 8px;
    width: fit-content;
    padding: 4px 8px;
    color: var(--white);
    font-size: 10px;
    background: var(--pink);
    opacity: 1;
    visibility: visible;
    pointer-events: none;
    transition: opacity 0.25s;
    max-width: 250px;
    min-width: 150px;
    text-align: left;
  }

  &__top {
    top: -5px;
    left: 0;
    transform: translate(0%, -100%);
  }

  &__left {
    top: 0;
    left: -5px;
    transform: translate(-100%, 0);
  }

  &__right {
    top: 0;
    left: calc(100% + 5px);
  }

  &__bottom {
    bottom: -5px;
    left: 0;
    transform: translate(0%, 100%);
  }

  &__visible {
    z-index: 5;
    opacity: 1;
    visibility: visible;
  }

  &__fade-out {
    opacity: 0;
    visibility: visible;
  }

  .icon:before {
    color: var(--gray);
    font-size: 14px;
  }

  .icon:hover::before {
    cursor: pointer;
  }
}
