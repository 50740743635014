.sfw-pill,
.nsfw-pill {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--green);
  font-family: "Roboto Regular";
  font-size: 12px;
  font-weight: 500;
  color: var(--charcoal);
}

.nsfw-pill {
  background-color: var(--pink-light);
}

.creator-content-settings {
  width: 100%;
  height: 100%;
  display: grid;
  gap: 16px;
  grid-template-rows: 80px 48px auto;
  position: relative;
  .no-padding {
    padding: 0;
  }

  .creator-content-button-navigation {
    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(to bottom, var(--black) 20%, transparent 40%);
      width: calc(100% - 48px);
      height: 100px;
      top: 168px;

      z-index: 1;
      pointer-events: none;
    }
  }

  .creator-content-settings__list-items {
    height: calc(100dvh - 426px);

    padding-bottom: 16px;
    padding-top: 16px;

    .tags-container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .created-tag {
        color: var(--faded);
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        padding: 8px 16px;
        gap: 8px;
        border-radius: 16px;
        background-color: var(--gray-dark);
        .icon-close {
          cursor: pointer;
        }
      }
    }

    &.part-two {
      height: calc(100dvh - 330px);
    }
  }

  .creator-content-settings__buttons-container {
    align-self: flex-end;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(to top, var(--black) 20%, transparent 40%);
      width: 100%;
      height: 100px;
      bottom: 120px;
      // pointer-events: none;
    }
  }

  .creator-content-settings__separator {
    width: 100%;
    height: 1px;
    background-color: var(--charcoal);
  }

  @media only screen and (min-width: 48em) {
    padding: 0 24px;

    .page-title-container {
      background: transparent;

      .back-button {
        background-color: var(--charcoal);
      }
    }

    .creator-content-button-navigation {
      &::before {
        content: "";
        position: absolute;
        background: linear-gradient(
          to bottom,
          var(--midnight) 20%,
          transparent 40%
        );
        width: calc(100% - 48px);
        height: 100px;

        top: 146px;
        z-index: 1;
        pointer-events: none;
      }
    }

    .creator-content-settings__buttons-container {
      margin-bottom: 24px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background: linear-gradient(
          to top,
          var(--midnight) 20%,
          transparent 40%
        );
        width: 100%;
        height: 100px;
        bottom: 120px;
        pointer-events: none;
      }
    }
  }
}
