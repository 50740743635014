#simple-select {
    margin: 4px 0;
    border: none;
    border-radius: 24px;
    width: 100%;
    height: 48px;
    padding: 14px 0 14px 16px;
    color: var(--white);
    font-family: "Roboto Light";
    font-size: 14px;
    text-transform: capitalize;
    appearance: none;
    -webkit-appearance: none;
    background: var(--dawn) url("../../../assets/icons/bx-chevron-down.svg")
    no-repeat calc(100% - 16px) 50%;
    outline: 1px solid var(--gray-dark);

    &.disabled {
        background-color: #d4d4d4;
        cursor: inherit;
    }

    &.isWarning {
        outline: 1px solid var(--peach);
    }
}

#option-default {
    p {
        height: 48px;
        font-size: 14px;
    }
}
