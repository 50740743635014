@import "../../scss/functions.scss";
$rem-80: to-rem(80);
$rem-90: to-rem(90);

.user-settings-wallet {
    position: relative;

    height: calc(100dvh - #{$rem-80});
    flex-direction: column;
    //display: flex;
    //overflow: hidden;
    overflow-y: auto;

    .actions-wrapper {
        position: sticky;
        z-index: 10;
        top: 0;
        width: 100%;
        //padding: to-rem(16) 0;
        overflow-y: hidden;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 99.9%);

        @media only screen and (min-width: 48em) {
            top: 0;
        }
    }
    .available-roses-recharge,
    .transactions-activity {
        overscroll-behavior: none;
    }

    .transactions-activity {
        .list-container {
            gap: 0;

            .input-container {
                gap: 0;
            }

            .photo-container {
                display: flex;
                gap: to-rem(8);
            }
        }
    }

    .list-container-list-item {
        padding: to-rem(11.8) 0;

        label {
            line-height: 1.6;
        }

        .description {
            margin: 0;
            line-height: 1.5;
        }

        .value {
            line-height: 1.6;
        }

        .list-radio:checked:after {
            background-color: var(--pink-light);
        }
    }

    .redeem-credits {
        overscroll-behavior: none;

        .list-container {
            overflow-y: auto;

            .text-detail {
                font-weight: 400;
                font-size: to-rem(14);
                text-transform: uppercase;
            }
        }

        .redeem-credits__header {
            font-size: to-rem(12);
            line-height: 1.8;
        }

        .value {
            margin-right: to-rem(16);
        }
    }

    .show {
        display: flex;
    }

    .hidden {
        display: none;
    }
}

.user-settings-wallet__slid {
    .slid-up-content {
        height: 100%;
        max-height: 70%;
    }

    .user-settings-wallet__slide-children {
        height: calc(100% - #{$rem-90});
        overflow: visible auto;
    }
}

.redeem-modal {
    border-radius: to-rem(16);
    width: 100%;
    max-width: to-rem(342);
    background: var(--midnight);

    .redeem-modal__top {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .redeem-modal__logo {
        width: to-rem(24);
        height: to-rem(24);
    }

    .redeem-modal__title {
        font-size: to-rem(16);
    }
}
