.chat-list {
  .top-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 16px 24px;

    .page-title-container {
      padding: 16px 0;
    }
  }

  .list-item-secondary-container {
    cursor: pointer;

    .notification-count {
      border-radius: 100%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--pink-light);

      p {
        color: var(--black);
        font-weight: 600;
        font-size: 11px;
      }
    }

    .icon-left-border {
      img {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .notification-count {
    border-radius: 50%;
    height: 20px;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: var(--pink-light);
    aspect-ratio: 1 / 1;

    &__value {
      margin: 0;
      color: var(--black);
      font-weight: 600;
      font-size: 10px;
    }
  }
  .chat-list__subtitle,
  .chat-list__no-chats {
    font-size: 12px;
  }

  .list-item-link .image-circle {
    border-width: 4px;
  }

  .list-item-link .item__left-icon.icon-fill {
    padding: 0;
  }

  #input-text-default .input-icon-right {
    top: 12px;
    width: 24px;
    height: 24px;
  }

  .list-item-link.show {
    display: flex;
  }

  .list-item-link.hidden {
    display: none;
  }

  .list-item-link-action {
    border-radius: 8px;
    background: var(--charcoal);
  }
}
