#modal-initial-preferences {
    position: fixed;
    z-index: 13;
    top: 50%;
    left: 50%;
    border: none;
    border-radius: 24px;
    width: calc(100% - 48px);
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--midnight);
    max-width:460px;
    transform: translate(-50%, -50%);

    .modal-icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        text-align: center;

        img {
            width: 24px;
            height: 24px;
            padding: 3px;
        }
    }

    .buttons-wrapper {
        display: grid;
        padding-top: 16px;
        gap: 8px;

        #button {
            margin: 0;
        }
    }
}
