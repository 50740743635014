.membership-page {
    width: 100%;
    height: 100%;
    display: grid;
    // position: relative;
    .initial-image-slide-text-container,

    .slide-carousel-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

        &.hidden {
            opacity: 0;
        }

        &.visible {
            opacity: 1;
        }
    }

    .initial-image-slide-text-container {
        [alt="introduction slide"] {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .initial-text-container {
            position: absolute;
            z-index: 10;
            bottom: 40px;
            left: 24px;
            width: calc(100% - 48px);
            transition: opacity 2s ease-in-out;

            p {
                color: var(--gray-light);
                font-weight: 400;
                font-size: 14px;
            }

            [alt="Icon"] {
                width: 32px;
                height: 32px;
            }

            .initial-buttons-container {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }

        &::before {
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            background: linear-gradient(
            to top,
            rgb(0, 0, 0) 12%,
            rgb(0, 0, 0) 0%,
            rgba(0, 0, 0, 0.05) 50%
            );
            pointer-events: none;
        }
    }

    .display-membership-widescreen {
        width: 100%;
        height: 100vh;
        display: grid;

        [alt="introduction slide wide"] {
            height: 100%;
        }

        .initial-text-container-wide {
            display: grid;
            justify-self: center;
            align-self: center;
            gap: 16px;

            p {
                color: var(--gray-light);
                font-weight: 400;
                font-size: 14px;
            }

            [alt="icon"] {
                width: 25px;
            }

            .initial-buttons-container-wide {
                margin-top: 48px;
            }
        }
    }
}
