#image-circle-border {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: all 0.3s ease;

  &.clickable {
    cursor: pointer;
  }

  .image-circle-border__center-image {
    border-radius: 100%;
    padding: 2px;
    aspect-ratio: 1 / 1;
    object-fit: cover;

    &.charcoal-border {
      border: 2px solid var(--charcoal);
    }

    &.transparent-border {
      border: 4px solid rgba(0, 0, 0, 0.35);
    }

    &.pink-border {
      border: 2px solid var(--pink-light);
    }
    &.no-border {
      border: none;
      padding: 4px;
    }

    &.no-space-between-border {
      padding: 0;
    }

    &.padding-border {
      padding: 4px;
    }
  }
}
