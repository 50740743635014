.user-settings {
    .list-container {
        gap: 0;
    }

    .container-buttons {
        position: absolute;
        bottom: 40px;
        width: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .notifications-container {
        margin: 24px;
    }
}
