#sign-in-sign-up-modal {
  position: relative;
  background-color: var(--midnight);
  border-radius: 24px;
  margin: 24px;

  .sign-in-sign-up-modal__content {
    display: grid;

    .content__carousel-wrapper {
      display: none;
      max-width: 390px;
    }
    .content__form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-self: center;
      max-width: 342px;
      margin: 24px;
      height: calc(100dvh - 100px);
      overflow-y: auto;

      .form-wrapper__inputs {
        display: grid;
        gap: 16px;
      }
    }

    .content__buttons-container {
    }
  }

  @media only screen and (min-width: 48rem) {
    .sign-in-sign-up-modal__content {
      grid-template-columns: minmax(200px, 390px) minmax(300px, 600px);
      .content__carousel-wrapper {
        display: block;
      }
    }
  }
}
