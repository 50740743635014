.user-settings-location {
  display: flex;
  flex-direction: column;

  .container-button {
    position: relative;
    bottom: env(safe-area-inset-bottom, 16px);
    margin: 24px 24px;
  }

  .img_mock {
    position: sticky;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 360px;
    align-items: center;
    height: auto;
  }

  .div__content_scroll {
    height: calc(100dvh - 35rem);
    overflow-y: auto;
  }
}
.map-container {
  position: relative;
  // width: 80%;
}

.class-search {
  position: absolute;
  z-index: 10;
  margin: 24px 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.class-search input {
  color: white;
  border: 1px solid var(--gray-mid);
  border-radius: 30px;
}

.class-search input:focus {
  color: white;
}

.class-search input icon {
  background-image: url("../../assets/icons/loupe-gray.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
