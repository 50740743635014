.user-settings-change-profile {
  .user-settings-change-profile__main {
    .main__creators-list-wrapper {
      height: calc(100dvh - 260px);
      overflow-y: auto;
    }
  }

  @media only screen and (min-width: 48em) {
  }
}
