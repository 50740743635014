#terms {
  margin: 120px auto 0 auto;
  max-width: 1224px;

  &.terms-wrapper {
    .terms-wrapper-inner {
      hr {
        margin: 40px 0;
        border: none;
        outline: none;
        border-bottom: 1px solid var(--gray-dark);
      }

      p {
        margin: 8px 0;
        color: var(--default-color-text);
      }

      ul {
        padding: 16px 0;

        li {
          color: var(--default-color-text);
        }

        &.list-roman {
          li {
            list-style-type: lower-roman;
          }
        }
      }
    }
  }

  @media only screen and (width >= 48em) {
    #layout-default {
      align-content: unset;
    }
  }
}
