#input-text-default {
  width: 100%;

  .input-text-default__label {
    display: block;
  }

  [alt="closed eye"] {
    width: 18px;
    height: 15px;
    cursor: pointer;
  }

  [alt="right icon"] {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  [alt="opened eye"] {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .input-container {
    position: relative;

    .input-text {
      border: 1px solid var(--gray-dark);
      border-radius: 24px;
      width: 100%;
      height: 48px;
      background-color: var(--dawn);
      color: var(--white);
      font-size: 14px;
      display: grid;
      align-items: center;
      justify-content: center;
      &.isWarning {
        outline: 1px solid var(--peach);
      }

      &::placeholder {
        color: var(--gray);
      }

      &:disabled {
        background-color: var(--midnight);
      }
    }

    .input-text-icon-left {
      position: absolute;
      top: 16px;
      left: 18.5px;
      width: 15px;
      height: 15px;
      background-color: transparent;

      &.disabled-icon {
        opacity: 0.5;
      }
    }

    .input-text-icon-right {
      position: absolute;
      top: 16px;
      right: 18.5px;
      background-color: transparent;
    }

    .input-text-icon-right-24 {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 14px;
      right: 18.5px;
      background-color: transparent;
    }

    .element-node-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 50px;
    }
  }
}
