#input-text-default {
  .input-container .input-icon-right {
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
  }
}

.header-mobile-bottom-btn {
  width: 24px;
  height: 24px;
  background-color: transparent;

  .header-navigate-icon {
    &.icon:before {
      color: var(--gray);
      transition: color 0.3s;
    }
  }

  .header-navigate-icon-active {
    &.icon:before {
      color: var(--pink-light);
      transition: color 0.3s;
    }
  }
}

.right-buttons-container {
  position: relative;

  .sphere-icon {
    position: absolute;
    top: -2px;
    right: -2px;
    border: 2px solid var(--black);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: var(--pink-light);
  }
}

.container_search {
  display: flex;
  align-items: center;
  color: white;

  .left-buttons-container {
    margin: 16px;
    display: flex;
    gap: 16px;
  }

  .right-buttons-container {
    margin: 16px;
    display: flex;
    gap: 16px;
  }

  .container-input {
    width: 100%;
  }
}
