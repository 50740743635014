.unlock-file-modal {
  border-radius: 16px;
  width: 100%;
  height: 294px;
  background-color: var(--midnight);
  max-width: 342px;

  .unlock-file-modal__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    align-content: center;

    [alt="roses logo icon"] {
      width: 24px;
      height: 24px;
    }

    h3 {
      margin: 16px 0 0 0;
      font-weight: 500;
      font-size: 16px;
    }

    p {
      font-size: 14px;
      text-align: center;
    }

    .buttons-container {
      margin-top: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
