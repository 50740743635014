.options-container {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.separator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--charcoal);
}

.text-item {
  width: 100%;
  text-align: center;
  padding: 8px;
}
