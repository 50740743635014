.reset-password {
  //width: 100%;
  //height: 100dvh;

  .separator {
    width: 100%;
    height: 1px;
    background-color: var(--charcoal);
  }
}

.separator-margin {
  margin: 8px 0 8px 0;
  height: 1px;
}

.app-link {
  padding: 24px;
}

.buttons-container {
  margin: 24px 0 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //width: calc(100% - 50px);
  //position: absolute;
  //bottom: 0;
}
