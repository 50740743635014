.list-item-extract {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    color: var(--gray-dark);
    border-bottom: 1px solid var(--gray-dark);

    &:hover {
        cursor: pointer;
    }

    &:last-of-type {
        border-bottom: none;
    }

    &__top {
    }

    &__title {
        color: var(--white);
        font-size: 0.875rem;
    }

    &__date {
        color: var(--gray-light);
        font-size: 0.75rem;
    }

    &__infos {
        display: flex;
        align-items: center;
    }

    &__info {
        margin-left: 1rem;
        color: var(--gray-light);
        font-size: 0.875rem;

        &--amount {
            color: var(--gray-light);
        }

        &--positive {
            color: var(--green);
        }

        &--extract {
            min-width: 5rem;
            text-align: end;
        }
    }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
    }
}
