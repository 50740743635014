.page-title-container {
  position: sticky;

  z-index: 10;
  top: 0px;
  //width: 100%;
  display: flex;
  align-items: center;
  //padding: 16px 0 0 0;
  // height: 80px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  overflow: visible;

  &.background-transparent {
    background: transparent;
  }
  // position: sticky;
  // z-index: 10;
  // top: 0px;
  // width: 100%;
  // display: flex;
  // height: 80px;
  // align-items: center;
  // padding: 0 16px;
  // background: linear-gradient(
  // 180deg,
  // rgba(0, 0, 0, 1) 20%,
  // rgba(0, 0, 0, 0.1) 99.45%
  // );
  // overflow: visible;

  &::before {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    //background: rgb(0 0 0 / 9%);
    pointer-events: none;
  }

  // &::after {
  //     position: absolute;
  //     z-index: -1;
  //     bottom: -40px;
  //     left: 0;
  //     width: 100%;
  //     height: 40px;
  //     content: "";
  //     background: linear-gradient(
  //     180deg,
  //     rgba(0, 0, 0, 0.5) 50%,
  //     rgba(0, 0, 0, 0) 100%
  //     );
  //     pointer-events: none;
  // }

  .chat-icon {
    border-radius: 100%;
    height: 40px;
    cursor: pointer;

    &.new-moment-available {
      background: linear-gradient(
        150.65deg,
        var(--peach) 13.34%,
        var(--pink) 90.3%
      );
    }

    &.moment-was-seen {
      background-color: var(--charcoal);
    }

    .chat-icon-img {
      border-radius: 100%;
      width: 40px;
      height: 40px;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }

  .text-wrapper {
    width: 100%;
    height: 40px;
    background-color: transparent;
    // margin-bottom: 20px;
    // align-self: flex-end;

    .title {
      background-color: transparent;
      color: var(--white);
      font-family: "Montserrat Variable";
      font-weight: 500;
      font-size: 14px;
      letter-spacing: var(--letter-spacing);
      text-transform: uppercase;

      &.chat-title {
        text-transform: none;
      }
    }

    .subtitle-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;

      .chat-icon-status {
        border-radius: 100%;
        width: 8px;
        height: 8px;

        &.interest-online {
          background-color: var(--green);
        }

        &.interest-offline {
          background-color: var(--gray-light);
        }
      }

      .subtitle {
        background-color: transparent;
        color: var(--gray-light);
        font-weight: 400;
        font-size: 14px;
        // margin-top: 3px;
      }
    }
  }

  .back-button {
    margin-right: 16px;
    border-radius: 50%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--midnight);
    min-width: 48px;
    cursor: pointer;

    [alt="x back icon"] {
      width: 11px;
      height: 11px;
      background-color: transparent;
    }
  }

  .open-extra {
    display: flex;
    justify-content: flex-end;
    //width: 100%;

    .open-extra-button {
      border: none;
      border-radius: 24px;
      background-color: var(--midnight);
      padding: 14px 40px;
      color: var(--gray-light);
      font-family: "Montserrat Variable";
      font-weight: 600;
      font-size: 10px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
    }
  }
}

@media only screen and (min-width: 48em) {
  .page-title-container {
    padding: 16px 0 0 0;
  }
}
