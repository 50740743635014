.roses-credit {
    border: 1px solid var(--midnight);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--midnight);
    padding: 0.75rem 1rem;
    color: var(--gray);
    transition:
        color 0.2s,
        background-color 0.2s,
        border-color 0.2s;
    &:active,
    &--active {
        border-color: var(--pink-light);
        background-color: #ee4e871a;
        color: var(--white);

        .roses-credit__icon {
            background-image: url(../../assets/icons/logos/logo-fill-pink.svg);
        }

        .roses-credit__discount {
            background-color: #00000040;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &--gold {
        border-color: var(--gold-dark);
        background-color: rgba(255, 179, 107, 0.1);
        color: var(--white);

        .roses-credit__icon {
            background-image: url(../../assets/icons/logos/logo-fill-gold.svg);
        }
    }

    &__iconContainer {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
    }

    &__logo {
        width: 1.5rem;
        height: 1.5rem;
    }

    &__icon {
        width: 100%;
        height: 100%;
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../assets/icons/logos/logo-fill-gray.svg);
        transition: background-image 0.2s;
    }

    &__text {
        color: var(--white);
        font-weight: 700;
        font-size: 1.25rem;
    }

    &__values {
        color: var(--white);
        font-size: 0.875rem;

        &--old {
            display: inline-block;
            font-size: 0.75rem;
            opacity: 0.5;
            text-decoration: line-through;
        }
    }

    &__discount {
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
        color: var(--white);
        font-weight: 500;
        background: #ee4e8740;
    }
}
