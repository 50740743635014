#header-home-page {
  width: 100%;
  height: auto;
  padding: 16px;

  .login-signup-buttons-container {
    display: flex;
    gap: 16px;
    #button {
      width: inherit;
    }
  }
  .right-buttons-container-profile,
  .actions-container {
    border-radius: 24px;
    display: flex;
    background-color: var(--midnight);
    gap: 8px;

    .roses-and-golden-roses {
      display: flex;
      align-items: center;
      gap: 4px;

      .roses-text {
        margin: 0;
        padding-left: 16px;
        color: var(--white);
        font-family: "Roboto Regular";
        font-weight: 400;
        font-size: 14px;
      }

      .vertical-separator {
        color: var(--gray-dark);
      }

      .golden-roses-text {
        margin: 0;
        color: var(--gold-light);
        font-family: "Roboto Regular";
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .header-mobile-search {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;

    .left-buttons-container {
      display: flex;
      align-items: center;
      padding: 24px 24px;
      gap: 24px;

      img {
        width: 24px;
        height: 24px;
        padding: 3px;
        cursor: pointer;
      }
    }
  }

  .header-wide-container {
    display: none;
  }

  .header-mobile-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // margin-top: 64px;

    .icon-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo-text-header {
        display: inline-flex;
        gap: 8px;
        align-items: center;

        &.cursor {
          cursor: pointer;
        }

        p {
          margin: 0;
          font-family: "Roboto Bold";
          font-size: 24px;
          color: white;
          .beta {
            position: relative;
            bottom: 8px;
            left: 4px;
            border-radius: 4px;
            padding: 4px 6px;
            color: var(--white);
            font-weight: bold;
            font-size: 8px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            background: var(--pink-light);
          }
        }
      }

      [alt="Roses Logo"] {
        width: 1.87rem;
        height: 1.87rem;
      }

      [alt="left arrow icon"] {
        width: 0.65rem;
        height: 1.04rem;
        cursor: pointer;

        &:hover {
          transition: all ease 0.3s;
        }
      }
    }

    .input-and-toggle-container {
      display: flex;
      gap: 8px;
    }
  }

  @media only screen and (min-width: 48em) {
    padding: 16px 16px 0 16px;

    .right-buttons-container {
      padding: 0 24px;
    }

    .header-mobile-container {
      display: none;
    }

    .header-wide-container {
      width: 100%;
      display: flex;
      align-items: center;
      //gap: 24px;

      .logo-container {
        display: flex;
        align-items: center;
        gap: 8px;

        &.cursor {
          cursor: pointer;
        }

        img {
          width: 32px;
          height: 32px;
          padding: 4px;
        }

        h1 {
          font-family: "Roboto Bold";
          font-size: 24px;

          sup {
            font-size: 12px;
          }
        }
      }

      .div-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .center-content {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          #input-text-default {
            max-width: 600px;

            .input-icon-right {
              top: 50%;
              width: 24px;
              height: 24px;
              transform: translateY(-50%);
            }
          }

          .left-buttons-container {
            display: flex;
            align-items: center;
            padding: 0 24px;
            gap: 24px;

            img {
              width: 24px;
              height: 24px;
              padding: 3px;
              cursor: pointer;
            }
          }

          .icon-container {
            width: 100%;
            display: inline-flex;
            gap: 16px;
          }

          .input-and-toggle-container {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 16px;

            .input-text-search {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .header-navigate-btn {
    width: 24px;
    height: 24px;
    background-color: transparent;
    //padding: 0 24px;
  }

  .header-navigate-icon {
    &.icon:before {
      color: var(--gray);
      transition: color 0.3s;
    }
  }

  .header-navigate-icon-active {
    &.icon:before {
      color: var(--pink-light);
      transition: color 0.3s;
    }
  }

  .new-message-container {
    position: relative;

    .sphere-icon {
      position: absolute;
      top: -2px;
      right: -2px;
      border: 2px solid var(--black);
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background-color: var(--pink-light);
    }
  }
}
