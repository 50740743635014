.user-settings-edit-profile {
  height: calc(100dvh - 184px);
  overflow-y: auto;
  .user-settings-edit-profile__input-container {
    display: grid;
    gap: 16px;
  }

  .user-settings-edit-profile__main {
    margin: 16px 24px;

    .profile-link-container {
      display: flex;
      flex-direction: column;

      .profile-link-container__p {
        font-size: 12px;

        span {
          color: var(--white);
        }
      }
    }
  }
  .buttons-container {
    position: absolute;
    bottom: 0;
    margin-bottom: 24px;
    width: calc(100% - 50px);
  }
}
