#moments-circles-component {
  position: sticky;
  z-index: 10;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  overflow-y: hidden;
  // background: linear-gradient(
  //   to top,
  //   rgb(0 0 0 / 86%) 0px,
  //   rgb(0 0 0 / 72%) 100px,
  //   rgba(0, 0, 0, 0) 300px
  // );

  .moments-circles-component__self-moment {
    position: relative;
    .moments-circles-component__add-moments-container {
      position: absolute;
      left: 32px;
      top: 24px;
      background-color: var(--pink-light);
      border-top: 1px solid #ffffff80;
      border-radius: 100%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-items: center;
      cursor: pointer;
      transition: background-color 0.4s ease-out;

      &:hover {
        background-color: var(--pink-light-hover);
      }

      .icon {
        width: 100%;
        // height: 100%;
        text-align: center;
      }
    }
  }

  @media only screen and (min-width: 48em) {
    top: 0;
  }

  @media only screen and (min-width: 64em) {
    justify-content: flex-start;
  }
}
