.home-page {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  height: 100%;
  justify-items: center;
}

.catalog-container {
  grid-column: 1 / 2;
}

.account-login {
  grid-column: 2 / 3;
  max-width:460px;
  padding: 0 24px;
  border-left: 1px solid var(--gray-dark);
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  pointer-events: none;
  display: none;
}

.account-login.show {
  animation-name: slideIn;
  animation-duration: 0.3s;
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  pointer-events: auto;
  display: grid;
}

.account-login.hide {
  animation-name: slideOut;
  animation-duration: 0.3s;
  opacity: 1;
  transform: translateX(100%);
  visibility: visible;
  pointer-events: auto;
  display: grid;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
    opacity: 1;
  }
  to {
    transform: translateX(0%);
    opacity: 0;
  }
}
