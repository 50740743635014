.list-item-link {
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  padding: 16px;
  grid-template-columns: 1fr 24px;

  gap: 16px;

  &.column-for-image,
  &.column-for-image-and-node-without-arrow {
    grid-template-columns: 40px 1fr auto;
  }

  &.column-for-image-and-node {
    grid-template-columns: 40px 1fr auto auto;
  }

  &.column-for-node {
    grid-template-columns: 1fr auto auto;
    gap: 8px;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  .item__image-left-container {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.image-circle {
      border: 1px solid var(--charcoal);
      background-color: var(--dawn);

      &.icon-transparent {
        background: transparent;
      }
    }

    .item__left-icon {
      width: 18px;
      height: 18px;

      &.icon-fill {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        padding: 2px;
        object-fit: cover;
        object-position: top;
      }

      &.icon-big {
        width: 24px;
        height: 24px;
      }
    }
  }

  .item__main-container {
    .main-container__highlight-text {
      color: var(--white);
      font-weight: 400;
      font-size: 14px;
    }

    .main-container__simple-text {
      font-weight: 400;
      font-size: 12px;
    }
  }

  .list-item-link__right-node {
  }

  .arrow-right,
  .close-right {
    justify-self: center;
    cursor: pointer;
  }
}
