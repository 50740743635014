.icon-disclaimer {
  height: auto;
  display: flex;
  align-items: flex-start;
  background-color: transparent;
  align-content: center;
  max-width: 460px;

  .container-checkbox {
    position: relative;
    margin-bottom: 12px;
    height: 100%;
    display: block;
    align-items: center;
    padding-left: 35px;
    font-size: 22px;
    //height: 48px;
    cursor: pointer;

    input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 8px;
      left: 0;
      border-radius: 6px;
      background-color: var(--charcoal);
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;

      &:after {
        position: absolute;
        top: 2px;
        left: 8px;
        border: solid var(--charcoal);
        border-width: 0 2px 2px 0;
        width: 4px;
        height: 10px;
        display: none;
        content: "";
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      border-radius: 6px;
      background-color: var(--pink);
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;

      &:after {
        display: block;
      }
    }
  }

  img {
    margin-right: 14px;
    width: 14px;
    height: 14px;
    background-color: transparent;
  }
}
