#cover-image {
    position: relative;
    width: 100%;
    height: 0;
    opacity: 65%;

    .cover-image__principal-image {
        position: absolute;
        z-index: 0;
        top: 0px;
        left: 0;
        width: 100%;
        height: calc(100dvh - 200px);
        overflow-x: hidden;

        .principal-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: linear-gradient(
            to bottom,
            rgb(0 0 0) 0%,
            rgb(0 0 0 / 30%) 20%,
            rgb(0 0 0) 100%
            );
            pointer-events: none;
        }
    }

    @media only screen and (min-width: 64em) {
        .cover-image__principal-image {
            height: calc(100dvh - 150px);
        }
    }

    @media only screen and (min-width: 80em) {
        }
    }
