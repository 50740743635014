.chat-messages-container {
  // margin-top: 80px;
}

.chat-container-page {
  position: relative;
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-template-rows: 65px calc(100dvh - 130px);

  .chat-page__message textarea {
    border: 1px solid var(--gray-dark);
    padding: 16px 150px 16px 16px;
  }

  .messages-container {
    position: absolute;
    width: -webkit-fill-available;
    height: calc(100dvh - 80px);
    padding: 100px 16px;
    overflow-y: auto;
    overscroll-behavior: none;
  }

  .actions-input-chat {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 80px 16px 48px 16px;
    align-self: flex-end;
    gap: 16px;
    overflow: visible;
    background: linear-gradient(180deg, #0000, #000);
    pointer-events: none;

    &::after {
      position: absolute;
      z-index: 1;
      bottom: 80px;
      left: 0;
      width: 100%;
      height: 40px;
      content: "";
      pointer-events: none;
    }
  }

  @media only screen and (min-width: 48em) {
    // grid-template-rows: 80px calc(100vh - 130px);

    .messages-container {
      height: calc(100vh - 80px);
    }

    .actions-input-chat {
      padding: 80px 16px 24px 16px;
    }
  }
}
