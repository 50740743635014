.user-information {
  display: grid;
  grid-template-rows: auto auto 1fr;

  .user-information__main {
    .node-item-right-text {
      font-size: 14px;
      color: var(--gray-light);
    }
  }

  .user-information__buttons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: flex-end;
  }
}
