#media-modal-content {
  position: relative;
  user-select: none;
  display: flex;
  background-color: var(--black);

  z-index: 10;
  border-radius: 30px;

  .media-card__media-wrapper {
    border-radius: 24px;

    .media-wrapper__media-photo {
      border-radius: 24px;
    }
  }

  .media-card__media-wrapper {
    .media-wrapper__media-photo {
      height: 100%;
    }
  }
  #moments-track {
    position: relative;
    .media-wrapper__media-photo {
      height: 100%;
    }
  }

  .content-wrapper__progressbar-wrapper {
    position: absolute;
    z-index: 8;
    top: 24px;
    right: 24px;
    left: 24px;
    width: calc(100% - 48px);
    display: flex;
    gap: 8px;
  }

  .media-card-wrapper {
    height: 100dvh;

    .media-wrapper__media-photo {
      height: 100%;
    }
  }

  .media-creator-details {
    max-width: 390px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media only screen and (min-width: 48rem) {
    height: calc(100dvh - 48px);

    .media-card-wrapper {
      height: 100%;
      width: 390px;
    }
  }
}
