#dialog-default {
  position: fixed;
  top: 50%;
  left: 50%;
  border: none;
  width: 100%;
  max-width: 350px;
  transform: translate(-50%, -50%);
  background: none;
  user-select: none;

  .dialog-content {
    position: relative;
    border-radius: 16px;
    background-color: var(--midnight);
    padding: 24px;
    animation: fadeIn 0.3s ease-out;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .dialog-default__header {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      .header__icon-wrapper {
        border: 1px solid var(--gray-dark);
        width: fit-content;
        height: auto;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-wrapper__icon {
          padding: 11px;
        }
      }

      .header__title {
        font-family: "Montserrat Variable";
        font-size: 14px;
        font-weight: 500;
        color: var(--white);
        text-transform: uppercase;
        text-align: center;
      }
    }

    .dialog-default__body {
      .body__text {
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-light);
        text-align: center;
      }
    }

    .dialog-default__buttons-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
