.upload-document {
  display: grid;
  grid-template-rows: 80px auto 1fr;

  .upload-document__buttons-container {
    align-self: flex-end;
  }

  .upload-document__main {
    height: calc(100dvh - 315px);
    overflow-y: auto;
  }
}
