.action-button-group {
  width: 100%;
  display: flex;
  // grid-auto-columns: minmax(120px, auto);
  overscroll-behavior: none;
  // grid-auto-flow: column;
  justify-items: stretch;
  overflow-x: auto;

  .action-button {
    border: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    gap: 4px;
    //min-width: 150px;

    &.background-dawn {
      background-color: var(--dawn);
      &.active {
        border: none;
        background-color: var(--black);
      }
    }

    &.background-midnight {
      background-color: var(--midnight);
      &.active {
        border: none;
        background-color: var(--black);
      }
    }

    &.first-child {
      margin-left: 16px;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    &.last-child {
      margin-right: 16px;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    &.no-hor-margin {
      &.first-child {
        margin-left: 0;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }

      &.last-child {
        margin-right: 0;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
    }

    p {
      color: var(--gray);
      font-family: "Montserrat Variable";
      font-weight: 600;
      font-size: 10px;
      letter-spacing: var(--letter-spacing);
      text-transform: uppercase;

      &.active {
        color: var(--pink-light);
      }

      &.right-text {
        opacity: 0.65;
      }
    }
  }
  .action-button__button-right {
    border: none;
    height: 48px;
    width: 100%;
    max-width: 48px;
    min-width: 48px;
    background-color: var(--midnight);
    border-radius: 100%;
    margin-right: 16px;
    cursor: pointer;

    &.background-midnight {
      background-color: var(--midnight);
    }

    &.background-dawn {
      background-color: var(--dawn);
    }

    .button-right__icon {
    }
  }
}
